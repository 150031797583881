import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.dialog.html'
})
export class PasswordResetDialog {
  @Input() isVisible: boolean = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  
  submit$?: Observable<boolean>;
  resetForm: FormGroup = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email])
  });
  emailToReset?: string;
  errorMessage?: string;

  constructor(
    private authService: AuthService,
  ) {
    
  }

  reset() {
    this.emailToReset = this.resetForm.get('email')?.value;
    this.submit$ = this.authService.resetPassword(this.resetForm.get('email')?.value);
  }
}
