/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 11.2.0 (c820efb)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EventPackageContents { 
    /**
     * Note: This is a Foreign Key to `event_package.id`.<fk table=\'event_package\' column=\'id\'/>
     */
    package_id: string;
    /**
     * Note: This is a Foreign Key to `event_package_reward_type.id`.<fk table=\'event_package_reward_type\' column=\'id\'/>
     */
    reward_type_id: string;
    qty?: number;
    order: number;
    /**
     * Note: This is a Primary Key.<pk/>
     */
    id: string;
}

