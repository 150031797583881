<ng-container *ngrxLet="event$ as event; suspenseTpl: loading"><ng-container *ngrxLet="stats$ as stats">
  <ng-container *ngIf="event">
    <div>
      <div class="block bg-green-500 z-40 absolute w-full">
        <header>
          <!--<div @FadeInFast class="flex h-14 justify-between mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8 transition-opacity"></div>-->
          <nav class="mx-auto flex max-w-7xl items-center justify-between h-12 px-6">
            <div class="flex lg:flex-1">
              <p class="text-green-200 ml-2 cursor-pointer pt-2" [routerLink]="'/events/detail/' + eventId">{{ event.display_name }}</p>
              <svg class="h-full w-6 flex-shrink-0 text-green-300" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
            </div>
            <a (click)="closeMenu()" [routerLink]="'/events/edit/' + eventId + '/'" routerLinkActive="bg-white text-green-500" [routerLinkActiveOptions]="{exact: true}" class="rounded-md px-3 py-2 text-sm font-medium text-green-50 hover:bg-green-50 ml-1 mr-0.5 hidden sm:inline">
              <i class="fa-light fa-golf-flag-hole hidden md:inline"></i> Dashboard
            </a>
            <a (click)="closeMenu()" [routerLink]="'/events/edit/' + eventId + '/general'" routerLinkActive="bg-white text-green-500" [routerLinkActiveOptions]="{exact: true}" class="rounded-md px-3 py-2 text-sm font-medium text-green-50 hover:bg-green-50 mx-0.5 hidden md:inline">
              <i class="fa-light fa-pencil hidden md:inline"></i> Edit Event
            </a>
            <a (click)="closeMenu()" [routerLink]="'/events/edit/' + eventId + '/packages'" routerLinkActive="bg-white text-green-500" [routerLinkActiveOptions]="{exact: true}" class="rounded-md px-3 py-2 text-sm font-medium text-green-50 hover:bg-green-50 mx-0.5 hidden lg:inline">
              <i class="fa-light fa-layer-group hidden md:inline"></i> Packages
            </a>
            <a (click)="closeMenu()" [routerLink]="'/events/edit/' + eventId + '/financial'" routerLinkActive="bg-white text-green-500" [routerLinkActiveOptions]="{exact: true}" class="rounded-md px-3 py-2 text-sm font-medium text-green-50 hover:bg-green-50 mx-0.5 hidden lg:inline">
              <i class="fa-light fa-usd hidden md:inline"></i> Finances
            </a>
            <div class="flex flex-1 justify-end">
              <a (click)="closeMenu()" [routerLink]="'/events/detail/' + eventId" routerLinkActive="bg-white text-green-500" class="group rounded-md px-3 py-2 text-sm font-medium text-green-50 hover:bg-green-50 justify-end hidden lg:inline mr-2"><i class="fa-light fa-eye sm:hidden md:inline"></i> Preview</a>
              <button (click)="toggleMenu()" type="button" class="trigger group rounded-md px-3 py-2 text-sm font-medium text-green-50 hover:text-white justify-end lg:inline mr-2 -m-2.5 inline-flex items-center j p-2.5 ">
                <i class="fa fa-bars"></i>
                <span class="hidden md:inline ml-1">More</span>
                <i class="fa fa-chevron-down text-white/50 ml-1 text-sm"></i>
              </button>
            </div>
          </nav>
        </header>
      </div>
      <div @FlyOut *ngIf="this.isMenu" (outsideClick)="this.isMenu = false" outsideClick class="absolute inset-x-0 top-0 z-10 bg-white pt-24 shadow-lg ring-1 ring-gray-900/5">
        <div class="mx-auto grid max-w-7xl grid-cols-1 gap-2 px-6 py-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 sm:py-8 lg:grid-cols-3 lg:gap-4 lg:px-8 xl:gap-8">
          <div class="flex group relative lg:hidden items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"  (click)="closeMenu()" [routerLink]="'/events/edit/' + eventId">
            <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <i class="fa-light fa-golf-flag-hole text-gray-600 group-hover:text-green-600"></i>
            </div>
            <div class="flex-auto">
              <a [routerLink]="'/events/edit/' + eventId" class="block font-semibold text-gray-900">
                Dashboard
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">An overview for this event</p>
            </div>
          </div> 
          <div class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"  (click)="closeMenu()" [routerLink]="'/events/edit/' + eventId + '/general'">
            <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <i class="fa-light fa-pencil text-gray-600 group-hover:text-green-600"></i>
            </div>
            <div class="flex-auto">
              <a [routerLink]="'/events/edit/' + eventId + '/general'" class="block font-semibold text-gray-900">
                Edit Event
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">Edit the details for this event</p>
            </div>
          </div>
          <div class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50" (click)="closeMenu()" [routerLink]="'/events/edit/' + eventId + '/packages'">
            <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <i class="fa-light fa-layer-group text-gray-600 group-hover:text-green-600"></i>
            </div>
            <div class="flex-auto">
              <a [routerLink]="'/events/edit/' + eventId + '/packages'" class="block font-semibold text-gray-900">
                Event Packages
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">Packages for golfers and sponsors to purchase</p>
            </div>
          </div>
          <div class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50" (click)="closeMenu()" [routerLink]="'/events/edit/' + eventId + '/contacts'">
            <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <i class="fa-light fa-address-book text-gray-600 group-hover:text-green-600"></i>
            </div>
            <div class="flex-auto">
              <a [routerLink]="'/events/edit/' + eventId + '/contacts'" class="block font-semibold text-gray-900">
                Event Contacts
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">Invitations for golfers and sponsors to participate</p>
            </div>
          </div>
          <div class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50" (click)="closeMenu()" [routerLink]="'/events/edit/' + eventId + '/financial'">
            <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <i class="fa-light fa-usd text-gray-600 group-hover:text-green-600"></i>
            </div>
            <div class="flex-auto">
              <a [routerLink]="'/events/edit/' + eventId + '/financial'" class="block font-semibold text-gray-900">
                Event Finances <span *ngIf="stats" class="badge">{{ stats.totalDollars | currency }}</span>
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">View financials for this event</p>
            </div>
          </div>
          <div class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50" (click)="closeMenu()" [routerLink]="'/events/edit/' + eventId + '/prepaid'">
            <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <i class="fa-light fa-thumbs-up text-gray-600 group-hover:text-green-600"></i>
            </div>
            <div class="flex-auto">
              <a [routerLink]="'/events/edit/' + eventId + '/prepaid'" class="block font-semibold text-gray-900">
                Prepaid Packages 
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">View & create prepaid packages for this event</p>
            </div>
          </div>
          <div class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"  (click)="closeMenu()" [routerLink]="'/events/edit/' + eventId + '/golfers'">
            <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <i class="fa-light fa-golf-club text-gray-600 group-hover:text-green-600"></i>
            </div>
            <div class="flex-auto">
              <a [routerLink]="'/events/edit/' + eventId + '/golfers'" class="block font-semibold text-gray-900">
                Golfers <span *ngIf="stats" class="badge">{{ stats.ticketsSold }}</span>
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">View golfers for this event</p>
            </div>
          </div>
          <div class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50" (click)="closeMenu()" [routerLink]="'/events/edit/' + eventId + '/sponsors'">
            <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <i class="fa-light fa-hand-holding-usd text-gray-600 group-hover:text-green-600"></i>
            </div>
            <div class="flex-auto">
              <a [routerLink]="'/events/edit/' + eventId + '/sponsors'" class="block font-semibold text-gray-900">
                Sponsors <span *ngIf="stats" class="badge">{{ stats.sponsorsTotal }}</span>
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">View sponsors for this event</p>
            </div>
          </div>
          <div class="flex group relative lg:hidden items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"  (click)="closeMenu()" [routerLink]="'/events/detail/' + eventId">
            <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <i class="fa-light fa-eye text-gray-600 group-hover:text-green-600"></i>
            </div>
            <div class="flex-auto">
              <a [routerLink]="'/events/detail/' + eventId" class="block font-semibold text-gray-900">
                Preview
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">The public page for this event</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container></ng-container>
<ng-template #loading>
  <div class="block bg-green-500 z-40 absolute w-full">
    <div class="flex h-14 justify-between mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8">
      <nav class="sm:flex sm:items-center sm:space-x-4">
        <p>&nbsp;</p>
      </nav>
    </div>
  </div>
</ng-template>