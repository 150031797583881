/**
 * Utility
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PackageOrder } from './packageOrder';
import { Golfer } from './golfer';


export interface EventTicket { 
    id?: string;
    packageOrderId?: string;
    golferId?: string | null;
    invitedEmail?: string | null;
    golfer?: Golfer;
    packageOrder?: PackageOrder;
}

