/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 11.2.0 (c820efb)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GolfCourse { 
    /**
     * Note: This is a Primary Key.<pk/>
     */
    id: string;
    display_name: string;
    address: string;
    phone_number?: string;
    website?: string;
    city?: string;
    state?: string;
    country?: string;
    postal_code?: string;
    ext_ref_id: string;
    location?: string;
    /**
     * Note: This is a Foreign Key to `s3_file.id`.<fk table=\'s3_file\' column=\'id\'/>
     */
    profile_photo_id?: string;
    /**
     * Note: This is a Foreign Key to `s3_file.id`.<fk table=\'s3_file\' column=\'id\'/>
     */
    header_photo_id?: string;
    timezone: string;
}

