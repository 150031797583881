/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 11.2.0 (c820efb)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminPermission { 
    /**
     * Note: This is a Primary Key.<pk/>
     */
    user_id: string;
    /**
     * Note: This is a Primary Key.<pk/>
     */
    permission_section: AdminPermission.PermissionSectionEnum;
    actions: Array<string>;
}
export namespace AdminPermission {
    export type PermissionSectionEnum = 'events' | 'profiles' | 'print_items' | 'admin';
    export const PermissionSectionEnum = {
        Events: 'events' as PermissionSectionEnum,
        Profiles: 'profiles' as PermissionSectionEnum,
        PrintItems: 'print_items' as PermissionSectionEnum,
        Admin: 'admin' as PermissionSectionEnum
    };
}


