<div>
  <div class="bg-green-100 h-32 w-full object-cover lg:h-48 ">
    <img class="h-32 w-full object-cover lg:h-48" src="/assets/img/hero/bg2.jpg" alt="">
  </div>
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <div class="-mt-16 sm:-mt-18 sm:flex sm:items-end sm:space-x-5">
      <div class="flex">
        <span class="h-24 w-24 rounded-md ring-4 ring-white sm:h-32 sm:w-32 overflow-hidden">
         <i class="bg-green-50 fa-solid fa-golf-flag-hole text-8xl sm:text-9xl p-2 text-green-500"></i>
        </span>
      </div>
      <div class="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
        <div class="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
          <a *ngIf="this.backUrl != ''" class="back-btn text-sm" (click)="this.goBack()"><i class="fa-light fa-arrow-left"></i></a>
          <h1 class="truncate text-2xl font-bold text-gray-900">{{course.display_name}} <i class="fa-solid fa-badge-check text-green-600"></i></h1>
        </div>
        <div class="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
          <a class="call-to-action-white" target="_blank" [href]="'https://maps.google.com/?q='+course.display_name+' '+course.address+' '+course.city+', '+course.state">
            <i class="fa-solid fa-diamond-turn-right"></i> 
            <span class="ml-2">Directions</span>
          </a>
        </div>
        <div class="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
          <a [href]="'tel:'+course.phone_number" class="call-to-action-white">
            <i class="fa-light fa-phone"></i>
            <span class="ml-2">{{ course.phone_number }}</span>
          </a>
        </div>
        <div class="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
          <a class="call-to-action-white" routerLink="/contact" [queryParams]="{course_id: course.id}">
            <i class="fa-solid fa-seal-exclamation"></i> 
            <span class="ml-2">Claim <span class="sm:hidden md:inline-block">Course</span></span>
          </a>
        </div>
      </div>
    </div>
    <div class="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
      <a *ngIf="this.backUrl != ''" class="back-btn" (click)="this.goBack()"><i class="fa-light fa-arrow-left"></i></a>
      <h1 class="truncate text-2xl font-bold text-gray-900">{{course.display_name}} <i class="fa-solid fa-badge-check text-green-600"></i></h1>
    </div>
  </div>
</div>
<div class="mt-6 sm:mt-2 2xl:mt-5">
  <div class="border-b border-gray-200">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <!--
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <a [routerLink]="'/courses/detail/'+course.id" routerLinkActive="border-green-500 text-green-500" [routerLinkActiveOptions]="{exact: true}"  class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">Profile</a>
      </nav>
      -->
    </div>
  </div>
</div>

