import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';
import { AuthToken, AuthUser, AuthUserMetadata, AuthVerifyResponse } from '@app/models/auth-models.interface';
import jwt_decode, { JwtPayload, JwtHeader } from "jwt-decode";
import { LocalStorage } from '@app/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accessToken?:string;
  private redirectUrl?:string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private localStorage: LocalStorage,
  ) {
    let storedToken = this.localStorage.getItem('jwt');
    this.accessToken = storedToken ? 'Bearer ' + storedToken : undefined;
  }

  isLoggedIn(): boolean {
    if(this.accessToken) {
      let token = (<JwtPayload>jwt_decode(this.accessToken));
      if(token.exp) {
        return token.exp > Math.round(Date.now() / 1000);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  logout(): void {
    this.accessToken = undefined;
    this.localStorage.clear();
    this.navigateToRedirectUrl();
  }
  getAccessToken(): string | undefined {
    return this.isLoggedIn() ? this.accessToken : undefined;
  }
  setTokenFromVerify(response: AuthVerifyResponse) {
    this.setAccessToken(response.access_token);
  }
  setAccessToken(token: string) {
    this.accessToken = 'Bearer ' + token;
    this.localStorage.setItem('jwt', token);
  }
  getUserId(): string | undefined {
    let token = this.getAccessToken();
    if(token) {
      return (<JwtPayload>jwt_decode(token)).sub;
    } else {
      return undefined;
    }
  }
  refreshPage() {
    this.hardLoadUrl(this.router.url);
  }
  navigateToRedirectUrl() {
    console.log("Redirect URL: " + this.redirectUrl)
    if(this.redirectUrl) {
      this.hardLoadUrl(this.redirectUrl)
        .then(() => {
          this.redirectUrl = undefined;
        });
    }
  }
  private hardLoadUrl(url: string) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };
    return this.router.navigateByUrl(url, {onSameUrlNavigation: 'reload'});
  }
  setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }
  setRedirectUrlFragments(urlParts: string[]) {
    this.redirectUrl = urlParts.join('/');
  }

  login(email: string, password: string): Observable<boolean> {
    return this.http.post(environment.authUrl + '/token',{
      email: email,
      password: password
    },{
      observe: "response",
      responseType: "json",
      params: {
        grant_type: "password"
      }
    }).pipe(
    map(resp => {
      if(resp.ok) {
        this.setAccessToken((<AuthToken>resp.body).access_token);
        this.navigateToRedirectUrl();
      }
      return resp.ok;
    }));
  }

  signup(email: string, password: string, data: any): Observable<boolean> {
    return this.http.post(environment.authUrl + '/signup',{
      email: email,
      password: password,
      data: data
    },{
      observe: "response",
      responseType: "json"
    }).pipe(
    map(resp => {
      return resp.ok;
    }));
  }

  resendConfirmationEmail(email: string): Observable<boolean> {
    // https://github.com/supabase/gotrue/commit/a50b5a711b9c1df902a85edc71cc10314dcf8100
    return this.http.post(environment.authUrl + '/resend',{
      email: email,
      type: "signup"
    },{
      observe: "response",
      responseType: "json"
    }).pipe(
    map(resp => {
      return resp.ok;
    }));
  }

  resetPassword(email: string): Observable<boolean> {
    return this.http.post(environment.authUrl + '/recover',{
      email: email,
    },{
      observe: "response",
      responseType: "json"
    }).pipe(
    map(resp => {
      return resp.ok;
    }));    
  }

  updatePassword(password: string) {
    return this.updateUser({ password: password });
  }
  updateAuthData(email: string, data: AuthUserMetadata) {
    return this.updateUser({ 
      email: email,
      data: data 
    });
  }

  updateUser(args: any): Observable<boolean> {
    return this.http.put(environment.authUrl + '/user', args,{
      observe: "response",
      responseType: "json",
      headers: {
        'Authorization': this.accessToken ?? '',
      }
    })
    .pipe(
    map(resp => {
      return resp.ok;
    }));  
  }

  getUser() {
    return this.http.get<AuthUser>(environment.authUrl + '/user', {
      headers: {
        'Authorization': this.accessToken ?? '',
      }
    })
  }
}
