/**
 * Financial
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventPackageContent } from './eventPackageContent';
import { EventPackageRewardFee } from './eventPackageRewardFee';


export interface EventPackageRewardType { 
    id?: string;
    displayName?: string | null;
    printWidthPixels?: number | null;
    printHeightPixels?: number | null;
    eventPackageContents?: Array<EventPackageContent> | null;
    eventPackageRewardFee?: EventPackageRewardFee;
}

