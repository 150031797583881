import { Component, Input } from '@angular/core';
import { WPPost } from '@app/models/wordpress.interface';
import { FadeInFast } from '@app/components/animations';

@Component({
  selector: 'blog-wp-summary',
  templateUrl: './wp-summary.component.html',
  animations: [FadeInFast],
})
export class WpSummaryComponent {
  @Input() post: WPPost = {} as WPPost;
  @Input() template: number = 1;
  
  constructor(
    
  ) {
    
  }
  
}
