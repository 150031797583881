import { Injectable } from '@angular/core';
import { PackageOrderService } from '@lib/core';
import { PackageOrderDefaultParams as DefaultParams } from '@app/models/package-order-default-params.interface';
import { Observable, first, map } from 'rxjs';
import { GolfEvent } from '@app/models/golf-event.interface';
import { PackageOrder } from '@app/models/package-order.interface';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PackageOrderHelper {

  constructor(
    private service: PackageOrderService,
    private authService: AuthService,
  ) { 

  }

  getOrderById(orderId: string):Observable<PackageOrder> {
    let params = new DefaultParams();
    params.id = 'eq.' + orderId;
    return this.service.packageOrderGet(params)
    .pipe(map(array => array[0]))
    .pipe(first())
    .pipe(map(x => <PackageOrder>x));
  }

  getOrdersByEventId(eventId: string):Observable<PackageOrder[]> {
    let params = new DefaultParams();
    params.golfEventId = 'eq.' + eventId;
    return this.service.packageOrderGet(params)
    .pipe(map(x => <PackageOrder[]>x));
  }

  getMyCompletedOrders(): Observable<PackageOrder[]> {
    let params = new DefaultParams();
    params.purchaserUserId = 'eq.' + this.authService.getUserId();
    params.order = 'finalized_at.desc';
    return this.service.packageOrderGet(params)
      .pipe(map(x => <PackageOrder[]>x));
  }

  getEventsBySponsor(sponsor_id:string):Observable<GolfEvent[]> {
    let params = new DefaultParams();
    params.purchaserUserId = 'eq.'+sponsor_id;
    var output = this.service.packageOrderGet(params)
      .pipe(map(poa => poa.map(po => (<PackageOrder>po).golf_event)))
      .pipe(map(events => events.filter((event, index, self) => self.findIndex(e => e.id === event.id) === index)));
    return output;
  }


}
