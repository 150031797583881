/**
 * Utility
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CRSType = 0 | 1 | 2;

export const CRSType = {
    NUMBER_0: 0 as CRSType,
    NUMBER_1: 1 as CRSType,
    NUMBER_2: 2 as CRSType
};

