import { Injectable } from '@angular/core';
import { GolfEventService, RpcGetSponsorsByEventIdService } from '@lib/core';
import { GolfEvent, GolfEventCreate, GolfEventStatistics, EventSponsorWithPackage } from '@app/models/golf-event.interface';
import { Observable, first, map } from 'rxjs';
import { GolfEventDefaultParams as DefaultParams } from '@app/models/golf-event-default-params.class'
import { PackageOrderHelper } from './package-order-helper.service';
import { PackageOrder } from '@app/models/package-order.interface';
import { GolfEventPackageHelper } from './golf-event-package-helper.service';


@Injectable({
  providedIn: 'root'
})
export class GolfEventHelper {

  constructor(
    private service: GolfEventService,
    private orderHelper: PackageOrderHelper,
    private sponsorLookup: RpcGetSponsorsByEventIdService,
  ) { 
    
  }

  getAllEvents():Observable<GolfEvent[]> {
    let params = new DefaultParams();
    return this.service.golfEventGet(params)
      .pipe(map(x => <GolfEvent[]>x));
  }

  getFutureEvents():Observable<GolfEvent[]> {
    let params = new DefaultParams();
    params.eventDatetime = 'gt.' + new Date().toISOString();
    return this.service.golfEventGet(params)
      .pipe(map(x => <GolfEvent[]>x));
  }

  getEventsBySearch(search:string, future_only:boolean = false):Observable<GolfEvent[]> {
    let params = new DefaultParams();
    params.displayName = 'ilike.*' + search + '*';
    if(future_only){
      params.eventDatetime = 'gt.' + new Date().toISOString();
    }
    return this.service.golfEventGet(params)
      .pipe(map(x => <GolfEvent[]>x));
  }

  getEventById(id:string):Observable<GolfEvent> {
    let params = new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.golfEventGet(params)
      .pipe(map(array => array[0]))
      .pipe(first())
      .pipe(map(x => <GolfEvent>x));
  }

  getEventStatisticsById(id: string): Observable<GolfEventStatistics> {
    return this.orderHelper.getOrdersByEventId(id)
      .pipe(map(po => {
        let completed = po.filter(po => po.finalized_at != null);
        let golferOrders = completed.filter(po => po.event_package.category == 'golfer');
        let golferTickets = golferOrders.map(po => po.event_tickets).flat(1);
        let sponsorOrders = completed.filter(po => po.event_package.category == 'sponsor'); 
        let groupedSponsorOrders = this.groupOrderBy(sponsorOrders, (order: PackageOrder) => order.event_package_id);
        let packages = [...new Map(sponsorOrders.map(po => [po.event_package.id, po.event_package])).values()]

        return {
          totalDollars: completed.reduce((accumulator, po) => accumulator + GolfEventPackageHelper.formattedDollarToNumber(po.total ?? "0"), 0),
          ticketsSold: golferTickets.length,//.reduce((accumulator, et) => accumulator + po.event_tickets.length, 0),
          ticketsClaimed: golferTickets.filter(et => et.golfer_id).length,
          golferDollars: golferOrders.reduce((accumulator, po) => accumulator + GolfEventPackageHelper.formattedDollarToNumber(po.total ?? "0"), 0),
          sponsorsTotal: sponsorOrders.length,
          sponsorPackages: packages.map( p => {
            return {
              packageId: p.id,
              packageName: p.display_name,
              packagePrice: GolfEventPackageHelper.formattedDollarToNumber(p.price),
              sponsorCount: groupedSponsorOrders.get(p.id)?.length ?? 0,
              sponsorIds: groupedSponsorOrders.get(p.id)?.map(po => po.purchaser_user_id) ?? [],
              packageDollars: groupedSponsorOrders.get(p.id)?.reduce((accumulator, po) => accumulator + GolfEventPackageHelper.formattedDollarToNumber(po.total ?? "0"), 0) ?? 0
            }
          })
        };
      }));
  }
  // From https://stackoverflow.com/a/38327540/3341745
  groupOrderBy(list: PackageOrder[], keyGetter: (arg0: PackageOrder) => string) {
    const map = new Map<string,PackageOrder[]>();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
  }

  getEventsByOrganizer(owner_id:string):Observable<GolfEvent[]> {
    let params = new DefaultParams();
    params.ownerId = 'eq.' + owner_id;
    return this.service.golfEventGet(params)
      .pipe(map(x => <GolfEvent[]>x));
  }

  getEventsByCourse(course_id:string):Observable<GolfEvent[]> {
    let params = new DefaultParams();
    params.golfCourseId = 'eq.' + course_id;
    return this.service.golfEventGet(params)
      .pipe(map(x => <GolfEvent[]>x));
  }

  getSponsorshipsByEventId(eventId:string):Observable<EventSponsorWithPackage[]> {
    return this.sponsorLookup.rpcGetSponsorsByEventIdPost({args: {event_id: eventId}})
      .pipe(map(x => <EventSponsorWithPackage[]>x));
  }
  
  createEvent(input: GolfEventCreate): Observable<string|null> {
    return this.service.golfEventPost({
      golfEvent: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<GolfEvent>body).id;
    }));
  }

  updateEvent(eventId: string, input: GolfEventCreate): Observable<boolean> {
    return this.service.golfEventPatch({
      id: 'eq.' + eventId,
      golfEvent: <any>input
    }, 'response')
    .pipe(map(r => {
      return r.ok;
    }));
  }
  updateEventSetupStep(eventId: string, stepNumber: number): Observable<boolean> {
    return this.service.golfEventPatch({
      id: 'eq.' + eventId,
      golfEvent: <any>{
        setup_step: stepNumber
      }
    }, 'response')
    .pipe(map(r => {
      return r.ok;
    }));
  }
}
