/**
 * Financial
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventTicket } from './eventTicket';
import { S3File } from './s3File';


export interface Golfer { 
    id?: string;
    createdDate?: string;
    displayName?: string | null;
    profilePhotoId?: string | null;
    eventTickets?: Array<EventTicket> | null;
    profilePhoto?: S3File;
}

