import { Injectable } from '@angular/core';
import { GolferService } from '@lib/core';
import { Observable, first, map } from 'rxjs';
import { Golfer } from '@app/models/golfer.interface';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GolferHelper {

  constructor(
    private service: GolferService,
    private authService: AuthService,
  ) { }

  getById(id:string):Observable<Golfer> {
    return this.service.golferGet({id: 'eq.' + id})
      .pipe(map(array => array[0])).pipe(first());
  }

  getGolfersByIds(ids:string[]):Observable<Golfer[]> {
    return this.service.golferGet({id: 'in.(' + ids.join(',') + ')'});
  }

  getMyProfile():Observable<Golfer> {
    return this.service.golferGet({id: 'eq.' + this.authService.getUserId()})
      .pipe(map(array => array[0])).pipe(first());
  }

}
