import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Step } from '@app/interfaces/step.interface';

@Component({
  selector: 'ui-stepper',
  templateUrl: './stepper.component.html'
})
export class UiStepperComponent {
  @Input() steps:Step[] = [];

  _currentStep: number = 1;
  get currentStep(): number {
    return this._currentStep;
  }
  @Input('currentStep') set currentStep(currentStep: number) {
    this._currentStep = currentStep;
    this.currentStepChange.emit(currentStep);
  }
  @Output() currentStepChange = new EventEmitter<number>();

  _maxAllowedStep: number = 1;
  get maxAllowedStep(): number {
    return this._maxAllowedStep;
  }
  @Input('maxAllowedStep') set maxAllowedStep(maxAllowedStep: number) {
    this._maxAllowedStep = maxAllowedStep;
    this.maxAllowedStepChange.emit(maxAllowedStep);
  }
  @Output() maxAllowedStepChange = new EventEmitter<number>();

  constructor(
  ) { }

  setCurrentStep(step: number) {
    if(step > this.maxAllowedStep) {
      return;
    }
    this.currentStep = step;
    this.currentStepChange.emit(step);
  }
}
