/**
 * Financial
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PrintItem } from './printItem';
import { PrepaidPackage } from './prepaidPackage';
import { EventContact } from './eventContact';
import { GolfEvent } from './golfEvent';
import { EventTicket } from './eventTicket';
import { EventPackage } from './eventPackage';


export interface PackageOrder { 
    id?: string;
    paymentIntentId?: string | null;
    purchaserUserId?: string;
    eventPackageId?: string;
    golfEventId?: string;
    additionalDonation?: number;
    publishInFeed?: boolean | null;
    createdAt?: string | null;
    finalizedAt?: string | null;
    total?: number | null;
    eventContactId?: string | null;
    eventContact?: EventContact;
    eventPackage?: EventPackage;
    eventTickets?: Array<EventTicket> | null;
    golfEvent?: GolfEvent;
    prepaidPackages?: Array<PrepaidPackage> | null;
    printItems?: Array<PrintItem> | null;
}

