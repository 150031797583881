import { Environment } from '@app/interfaces/environment.interface';
export const environment:Environment = {
    production: false,
    frontendUrl: 'https://dev.mygolfevents.com',
    apiUrl: 'https://dev.api.mygolfevents.com/core',
    authUrl: 'https://dev.api.mygolfevents.com/auth',
    financialUrl: 'https://dev.api.mygolfevents.com/financial',
    utilityUrl: 'https://dev.api.mygolfevents.com/utility',
    blogUrl: 'https://blog.mygolfevents.com',
    stripeKey: 'pk_test_51NG7W8IhrYiIBka8G0wFw4VfxxUTC2KL9wxNJr20x91XYfOtuJotzDAApwcYmqeK1DxOYnMz9zJTwDRwQfXs0xMH00Jejoc22Q',
    googleMapsKey: 'AIzaSyDId9xyk9VgUREocqDKg2dHj8No_E6V44E',
};
