/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 11.2.0 (c820efb)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EventPackage { 
    /**
     * Note: This is a Primary Key.<pk/>
     */
    id: string;
    /**
     * Note: This is a Foreign Key to `golf_event.id`.<fk table=\'golf_event\' column=\'id\'/>
     */
    golf_event_id: string;
    category: EventPackage.CategoryEnum;
    display_name: string;
    order?: number;
    price: string;
    description?: string;
}
export namespace EventPackage {
    export type CategoryEnum = 'golfer' | 'sponsor';
    export const CategoryEnum = {
        Golfer: 'golfer' as CategoryEnum,
        Sponsor: 'sponsor' as CategoryEnum
    };
}


