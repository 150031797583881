import { Component, Input } from "@angular/core";

@Component({
  selector: 'ui-no-data-placeholder',
  templateUrl: './no-data-placeholder.component.html',
})
export class UiNoDataPlaceholderComponent {
  @Input('heading') heading: string = 'No Data';
  @Input('icon') icon: string = 'fa-info-circle';

  constructor() { }
  
}

