<div class="lg:border-b lg:border-t lg:border-gray-200 mb-3">
  <nav>
    <ol role="list" class="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200">
      <ng-container *ngFor="let step of steps; let i = index">
        <li class="relative overflow-hidden lg:flex-1">
          <div class="overflow-hidden border border-gray-200 rounded-t-md border-b-0 lg:border-0 rounded-b-md border-t-0">
            <ng-container *ngIf="i > this.maxAllowedStep; else clickable">
              <p class="group">
                <span class="absolute left-0 top-0 h-full w-1 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" aria-hidden="true"></span>
                <span class="flex items-start px-6 py-5 text-sm font-medium">
                  <span class="flex-shrink-0">
                      <span class="flex h-10 w-10 items-center justify-center rounded-full border-2" [ngClass]="this.currentStep == i ? 'border-green-600' : 'border-gray-300'">
                        <span [ngClass]="this.currentStep == i ? 'text-green-600' : 'border-gray-500'">{{this.step.display_num}}</span>
                      </span>
                  </span>
                  <span class="ml-4 mt-0.5 flex min-w-0 flex-col">
                    <span class="text-sm font-medium">{{this.step.name}}</span>
                    <span class="text-xs font-medium text-gray-500 xl:block lg:hidden ">{{this.step.desc}}</span>
                  </span>
                </span>
                <ng-container *ngIf="i > 0">
                  <div class="absolute inset-0 left-0 top-0 hidden w-3 lg:block" aria-hidden="true">
                    <svg class="h-full w-full text-gray-300" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                      <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
                    </svg>
                  </div>
                </ng-container>
              </p>
            </ng-container>
            <ng-template #clickable>
              <a (click)="this.setCurrentStep(step.setup_step)" class="group">
                <span class="absolute left-0 top-0 h-full w-1 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" [ngClass]="this.currentStep == i ? 'bg-green-600' : 'bg-transparent group-hover:bg-gray-200'" aria-hidden="true"></span>
                <span class="flex items-start px-6 py-5 text-sm font-medium">
                  <span class="flex-shrink-0">
                    <span *ngIf="this.currentStep <= i" class="flex h-10 w-10 items-center justify-center rounded-full border-2" [ngClass]="this.currentStep == i ? 'border-green-600' : 'border-gray-300'">
                      <span [ngClass]="this.currentStep == i ? 'text-green-600' : 'border-gray-500'">{{this.step.display_num}}</span>
                    </span>
                    <span *ngIf="this.currentStep > i" class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-green-500 bg-green-500">
                      <span class="text-white"><i class="fa-solid fa-check"></i></span>
                    </span>
                  </span>
                  <span class="ml-4 mt-0.5 flex min-w-0 flex-col">
                    <span class="text-sm font-medium">{{this.step.name}}</span>
                    <span class="text-xs font-medium text-gray-500 xl:block lg:hidden ">{{this.step.desc}}</span>
                  </span>
                </span>
                <ng-container *ngIf="i > 0">
                  <div class="absolute inset-0 left-0 top-0 hidden w-3 lg:block" aria-hidden="true">
                    <svg class="h-full w-full text-gray-300" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                      <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
                    </svg>
                  </div>
                </ng-container>
              </a>
            </ng-template>
            
          </div>
        </li>
      </ng-container>
    </ol>
  </nav>
</div>