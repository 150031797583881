/**
 * Utility
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PackageOrder } from './packageOrder';
import { PrepaidPackage } from './prepaidPackage';
import { EventPackageContent } from './eventPackageContent';
import { GolfEvent } from './golfEvent';
import { RuleCategory } from './ruleCategory';


export interface EventPackage { 
    category?: RuleCategory;
    id?: string;
    golfEventId?: string;
    displayName?: string | null;
    order?: number | null;
    price?: number;
    description?: string | null;
    eventPackageContents?: Array<EventPackageContent> | null;
    golfEvent?: GolfEvent;
    packageOrders?: Array<PackageOrder> | null;
    prepaidPackages?: Array<PrepaidPackage> | null;
}
export namespace EventPackage {
}


