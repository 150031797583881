import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GolfEvent } from '@app/models/golf-event.interface';
import { GolfEventHelper } from '@app/services/golf-event-helper.service';
import { Observable, of } from 'rxjs';
import { WPPost, WPMedia } from '@app/models/wordpress.interface';
import { BlogService } from '@app/services/blog.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  public events$: Observable<GolfEvent[]>;
  public posts$?: Observable<WPPost[]>;

  constructor(
    private router: Router,
    private golfEventHelper: GolfEventHelper,
    private blogService: BlogService,
  ) {
    this.events$ = this.golfEventHelper.getFutureEvents();//getUpcomingEvents(6);
    this.posts$ = this.blogService.getPosts(3,1);
  }

  start() {
    this.router.navigate(['/events/create']);
  }

}
