import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { WpImageComponent } from './wp-image/wp-image.component';
import { WpSummaryComponent } from './wp-summary/wp-summary.component';


@NgModule({
  declarations: [
    WpImageComponent,
    WpSummaryComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LetDirective,
  ],
  exports: [
    WpImageComponent,
    WpSummaryComponent,
  ]
})
export class BlogUiModule { }
