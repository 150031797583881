import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GolfEvent, GolfEventStatistics } from '@app/models/golf-event.interface';
import { Observable } from 'rxjs';
import { FadeInFast, OpacityScale, FlyOut } from '@app/components/animations';
import { GolfEventHelper } from '@app/services/golf-event-helper.service';

@Component({
  selector: 'ui-event-edit-tabs',
  templateUrl: './event-tabs.component.html',
  animations: [ 
    FadeInFast, 
    OpacityScale,
    FlyOut,
  ]
})
export class UiEventEditTabsComponent {
  @Input() eventId?:string;
  @Input() event$: Observable<GolfEvent> = new Observable<GolfEvent>();

  _isMenu: boolean = false;
  get isMenu(): boolean {
    return this._isMenu;
  }
  @Input('isMenu') set isMenu(isMenu: boolean) {
    this._isMenu = isMenu;
    this.isMenuChange.emit(isMenu);
  }
  @Output() isMenuChange = new EventEmitter<boolean>();

  stats$?: Observable<GolfEventStatistics>;

  constructor(
    private router: Router,
    private golfEventHelper: GolfEventHelper,
  ) {

  }

  getRouterUrl():string{
    return this.router.url;
  }

  ngOnInit() {
    this.event$.subscribe((golfEvent) => {
      if(golfEvent){
        this.stats$ = this.golfEventHelper.getEventStatisticsById(golfEvent.id);
      }
    });
  }

  closeMenu(){
    this.isMenu = false;
  }
  
  toggleMenu(){
    this.isMenu = !this.isMenu;
  }

  onMenuChange(event:any){
    let url = event?.target?.value ?? null;
    if(url){
      this.router.navigateByUrl(url);
    }
  }

  isActive(url:string):boolean{
    return this.router.url === url;
  }

}
