import { Component, Input } from "@angular/core";
import { GolfEvent } from '@app/models/golf-event.interface';

@Component({
  selector: 'ui-event-card',
  templateUrl: './event-card.component.html'
})
export class UiEventCardComponent {
  @Input() event: GolfEvent | null = null;
  @Input() i: number = 1;

  constructor(

  ) { }

  truncateOutput(input: string | undefined | null, length: number = 50): string {
    if(typeof input === 'undefined' || input === null) {
      return '';
    }
    if(input.length > length) {
      return input.substring(0, length) + '...';
    }
    return input;
  }
}
