import { Component, Input } from '@angular/core';
import { GolfEvent } from '@app/models/golf-event.interface';
import { CommonModule } from '@angular/common';
import { LetDirective } from '@ngrx/component';
import { UiModule } from '@app/components/ui/ui.module';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-upcoming-event',
  templateUrl: './upcoming-event.component.html',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    UiModule,
    RouterLink,
  ]
})
export class UpcomingEventComponent {
  @Input() event?: GolfEvent;
}
