
<form>
  <!--
    class="hidden lg:block"
  <h3 class="sr-only">Categories</h3>
  
  -->

  <div class="border-b border-gray-200 py-6">
    <h3 class="-my-3 flow-root">
      <button type="button" (click)="togglePriceFilter()" class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500" aria-controls="filter-section-0" aria-expanded="false">
        <span class="font-medium text-gray-900">Price</span>
        <span class="ml-6 flex items-center">
          <svg *ngIf='!showPriceFilter' class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
          </svg>
          <svg *ngIf='showPriceFilter' class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />
          </svg>
        </span>
      </button>
    </h3>
    <div *ngIf='showPriceFilter' class="pt-6" id="filter-section-0">
      <div class="space-y-4">
        <ng-container *ngFor="let price of prices; let i = index">
          <div class="flex items-center">
            <input id="filter-price-{{i}}" name="price[]" value="{{price.value}}" type="checkbox" [checked]="price.checked" class="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500" />
            <label for="filter-price-{{i}}" class="ml-3 text-sm text-gray-600">{{price.name}}</label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  
  <div class="border-b border-gray-200 py-6">
    <h3 class="-my-3 flow-root">
      <button type="button" (click)="toggleDistanceFilter()" class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500" aria-controls="filter-section-1" aria-expanded="false">
        <span class="font-medium text-gray-900">Distance</span>
        <span class="ml-6 flex items-center">
          <svg *ngIf='!showDistanceFilter' class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
          </svg>
          <svg *ngIf='showDistanceFilter' class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />
          </svg>
        </span>
      </button>
    </h3>
    <div *ngIf='showDistanceFilter' class="pt-6" id="filter-section-1">
      <div class="space-y-4">
        <ng-container *ngFor="let distance of distances; let i = index">
          <div class="flex items-center">
            <input id="filter-distance-{{i}}" name="distance[]" value="{{distance.value}}" type="checkbox" [checked]="distance.checked" class="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500">
            <label for="filter-distance-{{i}}" class="ml-3 text-sm text-gray-600">{{distance.name}}</label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="border-b border-gray-200 py-6">
    <h3 class="-my-3 flow-root">
      <button type="button" (click)="toggleDateFilter()" class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500" aria-controls="filter-section-2" aria-expanded="false">
        <span class="font-medium text-gray-900">Date</span>
        <span class="ml-6 flex items-center">
          <svg *ngIf='!showDateFilter' class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
          </svg>
          <svg *ngIf='showDateFilter' class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />
          </svg>
        </span>
      </button>
    </h3>
    <div *ngIf='showDateFilter' class="pt-6" id="filter-section-2">
      <div class="space-y-4">
        <ng-container *ngFor="let date of dates; let i = index">
          <div class="flex items-center">
            <input id="filter-date-{{i}}" name="date[]" value="{{date.value}}" type="checkbox" [checked]="date.checked" class="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500">
            <label for="filter-date-{{i}}" class="ml-3 text-sm text-gray-600">{{date.name}}</label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="border-b border-gray-200 py-6">
    <h3 class="-my-3 flow-root">
      <button type="button" (click)="toggleCategoryFilter()" class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500" aria-controls="filter-section-0" aria-expanded="false">
        <span class="font-medium text-gray-900">Category</span>
        <span class="ml-6 flex items-center">
          <svg *ngIf='!showCategoryFilter' class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
          </svg>
          <svg *ngIf='showCategoryFilter' class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />
          </svg>
        </span>
      </button>
    </h3>
    <div *ngIf='showCategoryFilter' class="pt-6" id="filter-section-0">
      <div class="space-y-4">
        <ng-container *ngFor="let category of categories; let i = index">
          <div class="flex items-center">
            <input id="filter-category-{{i}}" name="category[]" value="{{category.value}}" type="checkbox" [checked]="category.checked" class="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500">
            <label for="filter-category-{{i}}" class="ml-3 text-sm text-gray-600">{{category.name}}</label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</form>