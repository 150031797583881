import { Component } from "@angular/core";
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'ui-search-filter',
  templateUrl: './search-filter.component.html',
  animations: [ 
    trigger('opacityLong', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease', style({ opacity: 0 }))
      ])
    ]),
    trigger('translateLeftX', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in-out', style({  transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' }))
      ])
    ]),
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('150ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('translateRightX', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in-out', style({  transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({  opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ])
  ]
})
export class UiSearchFilterComponent {
  
  showPriceFilter = true;
  showDistanceFilter = true;
  showDateFilter = true;
  showCategoryFilter = true;

  prices = [
    { name: 'Any', value: 'any', checked: true },
    { name: '$', value: '1' },
    { name: '$$', value: '2' },
    { name: '$$$', value: '3' },
    { name: '$$$$', value: '4' },
  ];

  categories = [
    { name: 'Any', value: 'any', checked: true },
    { name: 'Charitable', value: 'charitable' },
    { name: 'Private Foundation', value: 'private+foundation' },
    { name: 'Political', value: 'political' },
    { name: 'Religious', value: 'religious' },
    { name: 'Community', value: 'community' },
    { name: 'Individual', value: 'individual' },
    { name: 'Other', value: 'other' },
  ];

  dates = [
    { name: 'Any', value: 'any', checked: true },
    { name: 'Today', value: 'today' },
    { name: 'Tomorrow', value: 'tomorrow' },
    { name: 'This Week', value: 'this-week' },
    { name: 'This Weekend', value: 'this-weekend' },
    { name: 'Next Week', value: 'next-week' },
    { name: 'Next Weekend', value: 'next-weekend' },
    { name: 'This Month', value: 'this-month' },
    { name: 'Next Month', value: 'next-month' },
  ];

  distances = [
    { name: 'Any', value: 'any', checked: true },
    { name: '5 Miles', value: '5' },
    { name: '10 Miles', value: '10' },
    { name: '25 Miles', value: '25' },
    { name: '50 Miles', value: '50' },
    { name: '100 Miles', value: '100' },
  ];

  constructor (){

  }

  togglePriceFilter(){
    this.showPriceFilter = !this.showPriceFilter;
  }
  toggleDistanceFilter(){
    this.showDistanceFilter = !this.showDistanceFilter;
  }
  toggleDateFilter(){
    this.showDateFilter = !this.showDateFilter;
  }
  toggleCategoryFilter(){
    this.showCategoryFilter = !this.showCategoryFilter;
  }
}

