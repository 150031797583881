import { Component, Input } from '@angular/core';
import { BlogService } from '@app/services/blog.service';
import { Observable, map, mergeMap, of } from 'rxjs';

@Component({
  selector: 'blog-wp-image',
  templateUrl: './wp-image.component.html',
})
export class WpImageComponent {
  url$?: Observable<string>;
  @Input() mediaId!: number;
  @Input() size: "medium" | "large" | "thumbnail" | "medium_large" | "1536x1536" | "2048x2048" | "full" = "thumbnail";
  @Input() class: string = "";


  constructor(
    private service: BlogService,
  ) {
    this.url$ = of("");

  }

  ngOnInit() {
    this.url$ = this.service.getMedia(this.mediaId)
      .pipe(map(media => media.media_details.sizes[this.size].source_url));
  }
}
