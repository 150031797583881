<div>
  <div>
    <img class="h-32 w-full object-cover lg:h-48" src="/assets/img/hero/bg2.jpg" alt="">
  </div>
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <div class="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
      <div class="flex">
        <span class="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32 overflow-hidden">
         <i class="bg-green-50 fa-solid fa-user text-8xl sm:text-9xl p-2 text-green-500"></i>
        </span>
      </div>
      <div class="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
        <div class="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
          <a *ngIf="this.backUrl != ''" class="back-btn text-sm" (click)="this.goBack()"><i class="fa-light fa-arrow-left"></i></a>
          <h1 class="truncate text-2xl font-bold text-gray-900">{{organizer.display_name}} <i class="fa-solid fa-badge-check text-green-600"></i></h1>
        </div>
        
        <div class="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
          <!--
          <a class="call-to-action-white">
            <i class="fa-light fa-envelope"></i>
            <span class="ml-2">Email</span>
          </a>
          <a class="call-to-action-white">
            <i class="fa-light fa-phone"></i>
            <span class="ml-2">Phone</span>
          </a>
          -->
          <!--
          <a class="call-to-action-white">
            <i class="fa-light fa-flag"></i>
          </a>
          -->
        </div>
      </div>
    </div>
    <div class="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
      <a *ngIf="this.backUrl != ''" class="back-btn text-sm" (click)="this.goBack()"><i class="fa-light fa-arrow-left"></i></a>
      <h1 class="truncate text-2xl font-bold text-gray-900">{{organizer.display_name}} <i class="fa-solid fa-badge-check text-green-600"></i></h1>
    </div>
  </div>
</div>

<div class="mt-6 sm:mt-2 2xl:mt-5">
  <div class="border-b border-gray-200">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <a [routerLink]="'/profile/'+organizer.id" [queryParams]="{back: this.backUrl}" routerLinkActive="border-green-500 text-green-500" [routerLinkActiveOptions]="{exact: true}"  class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">Profile</a>
        <a [routerLink]="'/profile/'+organizer.id+'/events'" [queryParams]="{back: this.backUrl}" [routerLinkActiveOptions]="{exact: true}"  routerLinkActive="border-green-500 text-green-500" class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">Events</a>
      </nav>
    </div>
  </div>
</div>