<div class="mx-auto w-full px-4 sm:px-6 lg:px-8">
  <div class="flex h-16 justify-between">
    <div class="flex">
      <div class="hidden sm:flex sm:space-x-8">
        <a routerLink="/search" routerLinkActive="text-green-500" class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 hover:text-gray-700">
            <i class="fa-light fa-search inline-flex items-center px-2 text-sm font-medium"></i>
            Find Events
        </a>
      </div>
    </div>
    <div class="flex">
        <div class="flex flex-shrink-0 items-center">
            <span routerLink="/" class="title-center brand cursor-pointer -m-1.5 p-1.5 text-3xl">my<span>golf</span>events</span>
        </div>
    </div>
    <div class="items-center hidden sm:flex sm:space-x-8">
      <div class="flex-shrink-0">
        <button routerLink="/events/create" (click)="closeMenu()" class="button">
          Start Event
        </button>
      </div>
      <div class="hidden sm:flex sm:items-center">
        <div class="relative ml-1">
          <div>
            <button type="button" (click)="toggleMenu()" class="trigger -m-1.5 flex items-center p-1.5" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
              <span class="sr-only">Open user menu</span>
              <ng-container *ngrxLet="user$ as user; suspenseTpl: noPhoto;">
                <ng-container *ngIf="user; else noPhoto;">
                  <ng-container *ngIf="user.user_metadata?.photo_url !== undefined && user.user_metadata?.photo_url != '' && user.user_metadata?.photo_url != null; else noPhoto;">
                    <img class="h-8 w-8 rounded-full bg-gray-50" src="{{user.user_metadata?.photo_url}}" alt="{{ user.user_metadata?.name }}">
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-template #noPhoto>
                <i class="fa-light fa-circle-user text-2xl h-8 w-8 rounded-full bg-gray-50"></i>
              </ng-template>
              <span class="hidden lg:flex lg:items-center">
                <span class="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                  <ng-container *ngrxLet="user$ as user">
                    <ng-container *ngIf="user">
                      {{ user.user_metadata?.name }}
                    </ng-container>
                  </ng-container>
                </span>
                <svg class="ml-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                </svg>
              </span>
            </button>
          </div>
          <!-- notLoggedIn -->
        </div>
      </div>
    </div>
    <div class="-mr-2 flex items-center sm:hidden">
      <span class="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
        <ng-container *ngrxLet="user$ as user">
          <ng-container *ngIf="user">
            {{ user.user_metadata?.name }}
          </ng-container>
        </ng-container>
      </span>
      <button type="button" (click)="toggleMenu()" class="trigger inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500" aria-controls="mobile-menu" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <i class="fa-solid fa-bars block h-6 w-6 p-1"></i>
      </button>
    </div>
  </div>
  <ng-container *ngIf="authService.isLoggedIn(); else notLoggedIn;">
    <div @OpacityScale *ngIf="this.isMenu" (outsideClick)="this.isMenu = false" outsideClick class="absolute inset-x-0 top-0 -z-10 bg-white pt-16 shadow-lg ring-1 ring-gray-900/5">
      <div class="mx-auto grid max-w-7xl grid-cols-1 gap-2 px-6 py-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 sm:py-10 lg:grid-cols-4 lg:gap-4 lg:px-8 xl:gap-8">
        <div class="sm:hidden">
          <div class="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm leading-6 hover:bg-gray-50 sm:flex-col sm:p-6" routerLink="/search" (click)="closeMenu()">
            <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <i class="text-gray-600 group-hover:text-green-600 fa-light fa-search text-lg"></i>
            </div>
            <div>
              <a class="font-semibold text-gray-900">
                Search
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">Find events nearby to participate in!</p>
            </div>
          </div>
          <div class="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm leading-6 hover:bg-gray-50 sm:flex-col sm:p-6" routerLink="/events/create" (click)="closeMenu()">
            <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <i class="text-gray-600 group-hover:text-green-600 fa-light fa-trophy-star text-lg"></i>
            </div>
            <div>
              <a class="font-semibold text-gray-900">
                Start Event
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">Start a new event using My Golf Events!</p>
            </div>
          </div>
        </div>

        <div class="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm leading-6 hover:bg-gray-50 sm:flex-col sm:p-6" routerLink="/account/golfer" (click)="closeMenu()">
          <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
            <i class="text-gray-600 group-hover:text-green-600 fa-light fa-golf-club text-lg"></i>
          </div>
          <div>
            <a class="font-semibold text-gray-900">
              Golfer Profile
              <span class="absolute inset-0"></span>
            </a>
            <p class="mt-1 text-gray-600">Take control of your golfing journey.</p>
          </div>
        </div>
        <div class="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm leading-6 hover:bg-gray-50 sm:flex-col sm:p-6" routerLink="/account/organizer/events" (click)="closeMenu()">
          <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
            <i class="text-gray-600 group-hover:text-green-600 fa-light fa-golf-flag-hole text-lg"></i>
          </div>
          <div>
            <a class="font-semibold text-gray-900">
              Organizer Profile
              <span class="absolute inset-0"></span>
            </a>
            <p class="mt-1 text-gray-600">View, create, and update your events.</p>
          </div>
        </div>
        <div class="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm leading-6 hover:bg-gray-50 sm:flex-col sm:p-6"  routerLink="/account/sponsor" (click)="closeMenu()">
          <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
            <i class="text-gray-600 group-hover:text-green-600 fa-light fa-hand-holding-usd text-lg"></i>
          </div>
          <div>
            <a class="font-semibold text-gray-900">
              Sponsor Profile
              <span class="absolute inset-0"></span>
            </a>
            <p class="mt-1 text-gray-600">Manage your sponsorships and assets.</p>
          </div>
        </div>
        <div class="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm leading-6 hover:bg-gray-50 sm:flex-col sm:p-6"  routerLink="/account/orders" (click)="closeMenu()">
          <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
            <i class="text-gray-600 group-hover:text-green-600 fa-light fa-cart-circle-check text-lg"></i>
          </div>
          <div>
            <a class="font-semibold text-gray-900">
              My Orders
              <span class="absolute inset-0"></span>
            </a>
            <p class="mt-1 text-gray-600">See tickets, signs, and important details.</p>
          </div>
        </div>
      </div>
      <div class="bg-gray-50">
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <ng-container *ngrxLet="isAdmin$ as isAdmin">
            <div class="grid grid-cols-1 divide-y divide-gray-900/5 sm:divide-x sm:divide-y-0 sm:border-x sm:border-gray-900/5" [ngClass]="{ 'sm:grid-cols-4': isAdmin, 'sm:grid-cols-3': !isAdmin }">
              <a (click)="closeMenu()" routerLink="/search" class="flex items-center gap-x-2.5 p-3 px-6 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 sm:justify-center sm:px-0">
                <i class="fa-light fa-search text-gray-400 h-5 w-5"></i>
                Find Events
              </a>
              <a (click)="closeMenu()" routerLink="/account" class="flex items-center gap-x-2.5 p-3 px-6 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 sm:justify-center sm:px-0">
                <i class="fa-light fa-user text-gray-400 h-5 w-5"></i>
                My Account
              </a>
              <a (click)="logout(); closeMenu();" class="flex items-center gap-x-2.5 p-3 px-6 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 sm:justify-center sm:px-0">
                <i class="fa-light fa-right-from-bracket text-gray-400 h-5 w-5"></i>
                Sign Out
              </a>
              <a *ngIf="isAdmin" (click)="navToAdmin()" class="flex items-center gap-x-2.5 p-3 px-6 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 sm:justify-center sm:px-0">
                <i class="fa-light fa-toolbox text-gray-400 h-5 w-5"></i>
                Admin Menu
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #notLoggedIn>
    <div @opacityScale *ngIf="this.isMenu" (outsideClick)="this.isMenu = false" outsideClick class="absolute inset-x-0 top-0 -z-10 bg-white pt-16 shadow-lg ring-1 ring-gray-900/5">
      <div class="mx-auto grid max-w-7xl grid-cols-1 gap-2 px-6 py-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 sm:py-10 lg:grid-cols-4 lg:gap-4 lg:px-8 xl:gap-8">
        <!--
        <div class="sm:hidden">
          <div class="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm leading-6 hover:bg-gray-50 sm:flex-col sm:p-6" routerLink="/search" (click)="closeMenu()">
            <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <i class="text-gray-600 group-hover:text-green-600 fa-light fa-search text-lg"></i>
            </div>
            <div>
              <a class="font-semibold text-gray-900">
                Search
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">Find events nearby to participate in!</p>
            </div>
          </div>
          <div class="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm leading-6 hover:bg-gray-50 sm:flex-col sm:p-6" routerLink="/events/create" (click)="closeMenu()">
            <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <i class="text-gray-600 group-hover:text-green-600 fa-light fa-trophy-star text-lg"></i>
            </div>
            <div>
              <a class="font-semibold text-gray-900">
                Start Event
                <span class="absolute inset-0"></span>
              </a>
              <p class="mt-1 text-gray-600">Start a new event using My Golf Events!</p>
            </div>
          </div>
        </div>
        -->
        <!-- Marketing Nav (Not Logged In) -->
        <div class="mb-0 sm:mb-6 lg:mb-0">
          <h3 class="text-sm font-semibold leading-6 text-gray-500">Solutions</h3>
          <ul role="list" class="mt-6 space-y-4">
            <li>
              <a routerLink="/solutions/golfers" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Golfers</a>
            </li>
            <li>
              <a routerLink="/solutions/organizers" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Organizers</a>
            </li>
            <li>
              <a routerLink="/solutions/sponsors" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Sponsors</a>
            </li>
          </ul>
        </div>
        <div class="mb-0 sm:mb-6 lg:mb-0">
          <h3 class="text-sm font-semibold leading-6 text-gray-500">Company</h3>
          <ul role="list" class="mt-6 space-y-4">
            <li>
              <a routerLink="/about" class="text-sm leading-6 text-gray-400 hover:text-gray-900">About</a>
            </li>
            <li>
              <a routerLink="/pricing" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Pricing</a>
            </li>
          </ul>
        </div>
        <div class="mt-0 lg:mt-6">
          <ul role="list" class="mt-6 space-y-4">
            <li>
              <a routerLink="/blog" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Blog</a>
            </li>
            <li>
              <a routerLink="/press" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Press</a>
            </li>
          </ul>
        </div>
        <div class="mt-0 lg:mt-6">
          <ul role="list" class="mt-6 space-y-4">
            <li>
              <a routerLink="/contact" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Contact</a>
            </li>
            <li>
              <a routerLink="/contact/support" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Support Center</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="bg-gray-50">
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 divide-y divide-gray-900/5 sm:divide-x sm:divide-y-0 sm:border-x sm:border-gray-900/5 sm:grid-cols-3">
            <a (click)="closeMenu()" routerLink="/search" class="flex items-center gap-x-2.5 p-3 px-6 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 sm:justify-center sm:px-0">
              <i class="fa-light fa-search text-gray-400 h-5 w-5"></i>
              Find Events
            </a>
            <a (click)="closeMenu()" routerLink="/events/create" class="flex items-center gap-x-2.5 p-3 px-6 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 sm:justify-center sm:px-0">
              <i class="fa-light fa-trophy-star text-gray-400 h-5 w-5"></i>
              Start Event
            </a>
            <a (click)="openSignInDialog(); closeMenu();" class="flex items-center gap-x-2.5 p-3 px-6 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 sm:justify-center sm:px-0">
              <i class="fa-light fa-right-to-bracket text-gray-400 h-5 w-5"></i>
              Sign In
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>