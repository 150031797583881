import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ui-event-edit-package-tabs',
  templateUrl: './event-package-tabs.component.html'
})
export class UiEventEditPackageTabsComponent {
  @Input() eventId?:string;
  @Input() packageId?:string;

  constructor(
    private router: Router
  ) { }

  onMenuChange(event:any){
    let url = event?.target?.value ?? null;
    if(url){
      this.router.navigateByUrl(url);
    }
  }

  isActive(url:string):boolean{
    return this.router.url === url;
  }

}
