/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 11.2.0 (c820efb)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EventTicket { 
    /**
     * Note: This is a Primary Key.<pk/>
     */
    id: string;
    /**
     * Note: This is a Foreign Key to `package_order.id`.<fk table=\'package_order\' column=\'id\'/>
     */
    package_order_id: string;
    /**
     * Note: This is a Foreign Key to `golfer.id`.<fk table=\'golfer\' column=\'id\'/>
     */
    golfer_id?: string;
    invited_email?: string;
}

