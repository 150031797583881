import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Organizer } from '@app/models/organizer.interface';
import { OrganizerHelper } from '@app/services/organizer-helper.service';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-choose-organizer-modal',
  templateUrl: './organizer-chooser.component.html'
})
export class UiOrganizerChooserModal {
  @Input() isVisible: boolean = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Input() initialId?: string;
  @Output() organizerSelected = new EventEmitter<string>();
  @Input() eventName?: string;
  selectedId?: string;
  selectedName?: string;

  searchForm: FormGroup = new FormGroup({
    searchText: new FormControl<string|undefined>('')
  });

  public searchResults$?: Observable<Organizer[]>;

  constructor(
    private helper: OrganizerHelper,
  ) {

  }

  modalOpened(isOpen: boolean) {
    this.isVisibleChange.emit(isOpen);
    if(isOpen) {
      this.selectedId = this.initialId;
      if(this.initialId) {
        this.searchResults$ = this.helper.getOrganizerById(this.initialId)
          .pipe(map(x => {
            this.selected(x.id, x.display_name)
            return [x]
          }));
      }
    }
  }

  search() {
    let term = this.searchForm.get('searchText')?.value;
    if(term) {
      this.searchResults$ = this.helper.searchByText(this.searchForm.get('searchText')?.value);
    } else {
      this.searchResults$ = this.helper.getAllOrganizers();
    }
  }

  selected(id: string, name?: string) {
    this.selectedId = id;
    this.selectedName = name;
  }

  save() {
    this.isVisible = false;
    this.organizerSelected.emit(this.selectedId);
    this.isVisibleChange.emit(this.isVisible);
  }

}
