/**
 * Utility
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PackageOrder } from './packageOrder';
import { PrepaidPackage } from './prepaidPackage';
import { GolfEvent } from './golfEvent';


export interface EventContact { 
    golfEventId?: string;
    email?: string | null;
    id?: string;
    name?: string | null;
    phone?: string | null;
    createdAt?: string;
    inviteLastSentAt?: string | null;
    eventLastViewedAt?: string | null;
    emailLastViewedAt?: string | null;
    golfEvent?: GolfEvent;
    packageOrders?: Array<PackageOrder> | null;
    prepaidPackages?: Array<PrepaidPackage> | null;
}

