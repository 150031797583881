import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login.dialog.html',
  animations: [ 
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('200ms ease-out', style({  opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class LoginDialog {
  @Input() signInVisible: boolean = false;
  @Output() signInVisibleChange = new EventEmitter<boolean>();
  @Input() signUpVisible: boolean = false;
  @Output() signUpVisibleChange = new EventEmitter<boolean>();
  @Input() forgotPasswordVisible: boolean = false;
  @Output() forgotPasswordVisibleChange = new EventEmitter<boolean>();

  loginForm: FormGroup = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    password: new FormControl<string>('', Validators.required)
  });
  errorMessage?: string;
  notConfirmed?: {
    message:string,
    resent: boolean
  };
  
  constructor(
    private authService: AuthService,
  ) {}

  openSignUpDialog() {
    this.signInVisible = false;
    this.signUpVisible = true;
    this.signInVisibleChange.emit(this.signInVisible);
    this.signUpVisibleChange.emit(this.signUpVisible);
  }

  openForgotPasswordDialog() {
    this.signInVisible = false;
    this.forgotPasswordVisible = true;
    this.signInVisibleChange.emit(this.signInVisible);
    this.forgotPasswordVisibleChange.emit(this.forgotPasswordVisible);    
  }

  toggleModal(){
    this.signInVisible = !this.signInVisible;
    this.signInVisibleChange.emit(this.signInVisible);
    this.signUpVisibleChange.emit(this.signUpVisible);
  }

  login() {
    this.errorMessage = undefined;
    this.authService.login(this.loginForm.get('email')?.value, this.loginForm.get('password')?.value)
      .subscribe(success => {
        if(success) {
          this.signInVisible = false;
        } else {
          this.errorMessage = "Login error"
        }
      }, error => {
        console.log(error)
        if(error.error) {
          let err = error.error;
          if(err.error == "invalid_grant" && err.error_description == "Email not confirmed") {
            this.notConfirmed = {
              message: err.error_description,
              resent: false
            };
          } else {
            this.errorMessage = error.error?.error_description ?? "Incorrect Username/Password";
          }
        }
      });
  }

  resendConfirmation() {
    this.authService.resendConfirmationEmail(this.loginForm.get('email')?.value)
      .subscribe(success => {
        if(success) {
          this.notConfirmed = {
            message: "Email confirmation re-sent",
            resent: true
          };
        } else {
          this.errorMessage = "Could not re-send"
        }
      }, error => {
        console.log(error)
        if(error.error) {
          this.errorMessage = error.error?.error_description ?? "Could not re-send";
        }
      });
  }

}
