<main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
  <div class="text-center">
    <p class="text-base font-semibold text-green-500">{{this.title}}</p>
    <h1 class="mt-4 text-3xl font-bold tracking-tight text-green-900 sm:text-5xl">{{this.heading}}</h1>
    <p class="mt-6 text-base leading-7 text-gray-600">{{this.paragraph}}</p>
    <div class="mt-10 flex items-center justify-center gap-x-6">
      <a (click)="this.nav.openSignInDialog()" class="rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:text-gray-100 hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500">Sign In</a>
    </div>
  </div>
</main>
  