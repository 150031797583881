import { Component } from "@angular/core";

@Component({
  selector: 'ui-main-footer',
  templateUrl: './main-footer.component.html',
})
export class UiMainFooterComponent {
  constructor(
    
  ) { }
 
  getCurrentYear(){
    let year = new Date().getFullYear();
    if(year == 2023) {
      return '';
    }
    return '-'+year.toString().slice(2);
  }
}

