/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 11.2.0 (c820efb)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PackageOrder { 
    /**
     * Note: This is a Primary Key.<pk/>
     */
    id: string;
    payment_intent_id?: string;
    purchaser_user_id: string;
    /**
     * Note: This is a Foreign Key to `event_package.id`.<fk table=\'event_package\' column=\'id\'/>
     */
    event_package_id: string;
    /**
     * Note: This is a Foreign Key to `golf_event.id`.<fk table=\'golf_event\' column=\'id\'/>
     */
    golf_event_id: string;
    additional_donation: string;
    publish_in_feed: boolean;
    created_at?: string;
    finalized_at?: string;
    total?: string;
    /**
     * Note: This is a Foreign Key to `event_contact.id`.<fk table=\'event_contact\' column=\'id\'/>
     */
    event_contact_id?: string;
}

