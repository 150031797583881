<div [formGroup]="form">
    <div class="flex items-center justify-between">
        <label [for]="controlName" class="label">{{ label }}</label>
    </div>
    <div class="mt-2 relative">
        <input type="text"
            class="input"
            [formControlName]="controlName" 
            [id]="controlName" 
            [name]="controlName" 
            [autocomplete]="controlName" 
            mask="(000) 000-0000"
            [showMaskTyped]="true"
            [validation]="true"
            >
    </div>
    <ng-container *ngIf="form.controls[controlName] as ctl">
        <div *ngIf="ctl.invalid && (ctl.dirty || ctl.touched)"
            class="alert">
            <br />
            
            <span *ngIf="ctl.errors?.['required']">
                Phone Number is required
            </span>
            <span *ngIf="ctl.errors?.['mask']">
                Must be a valid Phone Number
            </span>
        </div>
    </ng-container>
</div>
