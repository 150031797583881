import { Component, Input, Output, EventEmitter } from "@angular/core";
import { trigger, transition, style, animate } from '@angular/animations';
import { AuthService } from '@app/services/auth.service';
import { Router } from '@angular/router';
import { AuthUser } from '@app/models/auth-models.interface';
import { Observable } from 'rxjs';
import { AdminHelper } from "@app/services/admin-helper.service";
import { OpacityScale } from "@app/components/animations";

@Component({
  selector: 'ui-main-header',
  templateUrl: './main-header.component.html',
  animations: [ 
    OpacityScale,
  ]
})
export class UiMainHeaderComponent {
  user$: Observable<AuthUser>;
  isAdmin$: Observable<boolean>;
  _isMenu: boolean = false;
  get isMenu(): boolean {
    return this._isMenu;
  }
  @Input('isMenu') set isMenu(isMenu: boolean) {
    this._isMenu = isMenu;
    this.isMenuChange.emit(isMenu);
  }
  @Output() isMenuChange = new EventEmitter<boolean>();

  _signInVisible: boolean = false;
  get signInVisible(): boolean {
    return this._signInVisible;
  }
  @Input('signInVisible') set signInVisible(signInVisible: boolean) {
    this._signInVisible = signInVisible;
    this.signInVisibleChange.emit(signInVisible);
  }
  @Output() signInVisibleChange = new EventEmitter<boolean>();
  
  constructor(
    public authService: AuthService,
    private router: Router,
    private adminHelper: AdminHelper,
  ) {
    this.user$ = this.authService.getUser();
    this.isAdmin$ = this.adminHelper.isAdmin();
  }

  closeMenu(){
    this.isMenu = false;
  }
  toggleMenu(){
    this.isMenu = !this.isMenu;
  }
  openSignInDialog() {
    this.authService.setRedirectUrl(this.router.url);
    this.signInVisible = true;
  }
  logout() {
    this.authService.setRedirectUrl(this.router.url);
    this.authService.logout();
  }
  navToAdmin() {
    this.router.navigate(['admin'])
    this.closeMenu();
  }
}

