import { Injectable } from '@angular/core';
import { SponsorService } from '@lib/core';
import { Observable, first, map } from 'rxjs';
import { Sponsor, SponsorCreate } from '@app/models/sponsor.interface';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SponsorHelper {

  constructor(
    private service: SponsorService,
    private authService: AuthService,
  ) { }

  getSponsorById(id:string):Observable<Sponsor> {
    return this.service.sponsorGet({id: 'eq.' + id})
      .pipe(map(array => array[0])).pipe(first());
  }

  getSponsorsByIds(ids:string[]):Observable<Sponsor[]> {
    return this.service.sponsorGet({id: 'in.(' + ids.join(',') + ')'});
  }

  getMyProfile():Observable<Sponsor> {
    return this.service.sponsorGet({id: 'eq.' + this.authService.getUserId()})
      .pipe(map(array => array[0])).pipe(first());
  }

  createProfile(input: SponsorCreate): Observable<boolean> {
    return this.service.sponsorPost({
      sponsor: <any>input,
      prefer: 'resolution=merge-duplicates'
    }, 'response')
    .pipe(map(r => r.ok));
  }

}
