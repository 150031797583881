<nav class="flex border-b border-gray-200 bg-white" aria-label="Breadcrumb" *ngIf="this.links.length > 0">
  <ol role="list" class="mx-auto flex w-full max-w-7xl space-x-4 px-4 sm:px-6 lg:px-8">
    <ng-container *ngFor="let link of links; let first = first;">
      <li class="flex" *ngIf="link.url != ''">
        <div class="flex items-center">
          <svg *ngIf="!first" class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" aria-hidden="true">
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <a [routerLink]="link.url" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{link.text}}</a>
        </div>
      </li>
    </ng-container>
  </ol>
</nav>