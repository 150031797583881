import { Component, Input } from "@angular/core";
import { NavigationWrapperComponent } from '@app/navigation-wrapper/navigation-wrapper.component'

@Component({
  selector: 'ui-not-logged-in',
  templateUrl: './not-logged-in.component.html',
})
export class UiNotLoggedInComponent {
  @Input('title') title: string = 'FORE!';
  @Input('heading') heading: string = 'Just a Moment';
  @Input('paragraph') paragraph: string = 'You must be signed in to complete this action.';

  constructor(
    public nav: NavigationWrapperComponent,
  ) { }
  
}

