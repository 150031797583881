import { Component, Input } from "@angular/core";
import { GolfEvent } from '@app/models/golf-event.interface';

@Component({
  selector: 'ui-event-carousel',
  templateUrl: './event-carousel.component.html',
})
export class UiEventCarouselComponent {
  @Input() event: GolfEvent | null = null;

  possibleImages = [
    '/assets/img/event/4.jpg',
    '/assets/img/event/5.jpg',
    '/assets/img/event/6.jpg',
    '/assets/img/event/3.jpg',
    '/assets/img/event/2.jpg',
  ];

  public currentImage:string = '';

  constructor(
    
  ) {
    if(this.possibleImages.length > 0){
      this.setCurrentImage(this.possibleImages[0]);
    }
  }

  setCurrentImage(image: string) {
    this.currentImage = image;
  }

  next(){
    let index = this.possibleImages.indexOf(this.currentImage);
    if(index == this.possibleImages.length - 1) {
      this.currentImage = this.possibleImages[0];
    } else {
      this.currentImage = this.possibleImages[index + 1];
    }
  }

  prev(){
    let index = this.possibleImages.indexOf(this.currentImage);
    if(index == 0) {
      this.currentImage = this.possibleImages[this.possibleImages.length - 1];
    } else {
      this.currentImage = this.possibleImages[index - 1];
    }
  }
}
