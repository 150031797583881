<div>
  <div class="sm:hidden ">
    <label for="tabs" class="sr-only">Select a tab</label>
    <select (change)="this.onMenuChange($event)" id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm">
      <option value="/account/organizer/events" [selected]="this.isActive('/account/organizer/events')">Events</option>
      <option value="/account/organizer/profile" [selected]="this.isActive('/account/organizer/profile')">Profile</option>
      <!-- <option value="/account/organizer/notifications" [selected]="this.isActive('/account/organizer/notifications')">Notifications</option> -->
      <option value="/account/organizer/financial" [selected]="this.isActive('/account/organizer/financial')">Financial</option>
    </select>
  </div>
  <div class="hidden sm:block bg-green-500">
    <div class="flex h-14 justify-between mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8">
      <nav class="hidden sm:flex sm:items-center sm:space-x-4">
        <p class="text-green-200 ml-2">Organizer</p>
        <svg class="h-full w-6 flex-shrink-0 text-green-300" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" aria-hidden="true">
          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
        </svg>
        <a routerLink="/account/organizer/events" routerLinkActive="bg-white text-green-500" class="rounded-md px-3 py-2 text-sm font-medium text-green-50 hover:bg-green-50"><i class="fa-light fa-calendar"></i> Events</a>
        <a routerLink="/account/organizer/profile" routerLinkActive="bg-white text-green-500" class="rounded-md px-3 py-2 text-sm font-medium text-green-50 hover:bg-green-50"><i class="fa-light fa-user"></i> Profile</a>
        <!-- <a routerLink="/account/organizer/notifications" routerLinkActive="bg-white text-green-500" class="rounded-md px-3 py-2 text-sm font-medium text-green-50 hover:bg-green-50"><i class="fa-light fa-comment"></i> Notifications</a> -->
        <a routerLink="/account/organizer/financial" routerLinkActive="bg-white text-green-500" class="rounded-md px-3 py-2 text-sm font-medium text-green-50 hover:bg-green-50"><i class="fa-light fa-usd"></i> Financial</a>
      </nav>
    </div>
  </div>
</div>
