/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 11.2.0 (c820efb)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface S3File { 
    /**
     * Note: This is a Primary Key.<pk/>
     */
    id: string;
    created_at: string;
    owner_id: string;
    path: string;
    thumbnail_path: string;
    filename: string;
    file_type: S3File.FileTypeEnum;
    is_public?: boolean;
    pixel_width?: number;
    pixel_height?: number;
}
export namespace S3File {
    export type FileTypeEnum = 'image_component' | 'image_render' | 'image_profile_photo' | 'image_sponsor_logo' | 'image_event_profile' | 'image_event_header' | 'image_organizer_profile' | 'image_organizer_header' | 'image_course_profile' | 'image_course_header';
    export const FileTypeEnum = {
        Component: 'image_component' as FileTypeEnum,
        Render: 'image_render' as FileTypeEnum,
        ProfilePhoto: 'image_profile_photo' as FileTypeEnum,
        SponsorLogo: 'image_sponsor_logo' as FileTypeEnum,
        EventProfile: 'image_event_profile' as FileTypeEnum,
        EventHeader: 'image_event_header' as FileTypeEnum,
        OrganizerProfile: 'image_organizer_profile' as FileTypeEnum,
        OrganizerHeader: 'image_organizer_header' as FileTypeEnum,
        CourseProfile: 'image_course_profile' as FileTypeEnum,
        CourseHeader: 'image_course_header' as FileTypeEnum
    };
}


