import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserContactInformation } from '@app/models/user-contact-information.interface';
import { UserContactHelper } from '@app/services/user-contact-helper.service';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-choose-user-contact-modal',
  templateUrl: './user-contact-chooser.component.html'
})
export class UiUserContactChooserModal {
  @Input() isVisible: boolean = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Input() initialId?: string;
  @Output() userSelected = new EventEmitter<string>();
  selectedId?: string;
  selectedName?: string;

  searchForm: FormGroup = new FormGroup({
    searchText: new FormControl<string|undefined>('')
  });

  public searchResults$?: Observable<UserContactInformation[]>;

  constructor(
    private helper: UserContactHelper,
  ) {

  }

  modalOpened(isOpen: boolean) {
    this.isVisibleChange.emit(isOpen);
    if(isOpen) {
      this.selectedId = this.initialId;
      if(this.initialId) {
        this.searchResults$ = this.helper.getById(this.initialId)
          .pipe(map(x => {
            this.selected(x.id, x.display_name)
            return [x]
          }));
      }
    }
  }

  search() {
    let term = this.searchForm.get('searchText')?.value;
    if(term) {
      this.searchResults$ = this.helper.searchByText(this.searchForm.get('searchText')?.value);
    } else {
      this.searchResults$ = this.helper.getAll();
    }
  }

  selected(id: string, name?: string) {
    this.selectedId = id;
    this.selectedName = name;
  }

  save() {
    this.isVisible = false;
    this.userSelected.emit(this.selectedId);
    this.isVisibleChange.emit(this.isVisible);
  }

}
