import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'head-meta',
  templateUrl: './meta.component.html',  
})
export class HeadMetaComponent {
  private appTitle = 'My Golf Events';

  private title$:BehaviorSubject<string> = new BehaviorSubject<string>(this.appTitle);
  private description$:BehaviorSubject<string> = new BehaviorSubject<string>(this.appTitle);  
  private image$:BehaviorSubject<string> = new BehaviorSubject<string>(environment.frontendUrl+'/assets/img/logo/og-share.png');
  private imageWidth$:BehaviorSubject<number> = new BehaviorSubject<number>(1200);
  private imageHeight$:BehaviorSubject<number> = new BehaviorSubject<number>(630);
  private url$:BehaviorSubject<string> = new BehaviorSubject<string>(environment.frontendUrl);
  private type$:BehaviorSubject<string> = new BehaviorSubject<string>('website');
    
  @Input('title') set title(title: string) {
    this.setTitle(title);
    this.titleChange.emit(title);
  }
  @Output() titleChange = new EventEmitter<string>();

  @Input('description') set description(description: string) {
    this.setDescription(description);
    this.descriptionChange.emit(description);
  }
  @Output() descriptionChange = new EventEmitter<string>();

  @Input('image') set image(image: string) {
    this.setImage(image);
    this.imageChange.emit(image);
  }
  @Output() imageChange = new EventEmitter<string>();

  @Input('imageWidth') set imageWidth(imageWidth: number) {
    this.setImageWidth(imageWidth);
    this.imageWidthChange.emit(imageWidth);
  }
  @Output() imageWidthChange = new EventEmitter<number>();

  @Input('imageHeight') set imageHeight(imageHeight: number) {
    this.setImageHeight(imageHeight);
    this.imageHeightChange.emit(imageHeight);
  }
  @Output() imageHeightChange = new EventEmitter<number>();

  @Input('url') set url(url: string) {
    this.setUrl(url);
    this.urlChange.emit(url);
  }
  @Output() urlChange = new EventEmitter<string>();

  @Input('type') set type(type: string) {
    this.setType(type);
    this.typeChange.emit(type);
  }
  @Output() typeChange = new EventEmitter<string>();

  constructor(
    private metaService: Meta,
    private titleService: Title,
  ) {
    this.title$.subscribe((title:string) => {
      this.titleService.setTitle(title);
      this.setMeta({
        property: 'og:title',
        content: title,
      });
    });
    this.description$.subscribe((description:string) => {
      this.metaService.updateTag({ name:'description', content: description });
      this.setMeta({
        property: 'og:description',
        content: description,
      });
    });
    this.image$.subscribe((image:string) => {
      if(image.indexOf('http') !== 0){
        image = environment.frontendUrl+image;
      }
      this.setMeta({
        property: 'og:image',
        content: image,
      });
    });
    this.imageWidth$.subscribe((imageWidth:number) => {
      this.setMeta({
        property: 'og:image:width',
        content: imageWidth.toString(),
      });
    });
    this.imageHeight$.subscribe((imageHeight:number) => {
      this.setMeta({
        property: 'og:image:height',
        content: imageHeight.toString(),
      });
    });
    this.url$.subscribe((url:string) => {
      if(url.indexOf('http') !== 0){
        url = environment.frontendUrl+url;
      }
      this.setMeta({
        property: 'og:url',
        content: url,
      });
    });
    this.type$.subscribe((type:string) => {
      this.setMeta({
        property: 'og:type',
        content: type,
      });
    }); 
    
  }

  private setMeta(meta: MetaDefinition){
    this.metaService.updateTag(meta);
  }

  private setTitle(input:string){
    this.title$.next(input);
  }

  private setDescription(input:string){
    this.description$.next(input);
  }

  private setImage(input:string){
    this.image$.next(input);
  }

  private setImageWidth(input:number){
    this.imageWidth$.next(input);
  }

  private setImageHeight(input:number){
    this.imageHeight$.next(input);
  }

  private setUrl(input:string){
    this.url$.next(input);
  }

  private setType(input:string){
    this.type$.next(input);
  }
}
