/**
 * Financial
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GolfCourse } from './golfCourse';
import { PrintItem } from './printItem';
import { S3FileType } from './s3FileType';
import { Organizer } from './organizer';
import { PrintTemplate } from './printTemplate';
import { GolfEvent } from './golfEvent';
import { Golfer } from './golfer';


export interface S3File { 
    fileType?: S3FileType;
    id?: string;
    createdAt?: string;
    ownerId?: string;
    path?: string | null;
    thumbnailPath?: string | null;
    filename?: string | null;
    isPublic?: boolean | null;
    pixelWidth?: number | null;
    pixelHeight?: number | null;
    golfCourseHeaderPhotos?: Array<GolfCourse> | null;
    golfCourseProfilePhotos?: Array<GolfCourse> | null;
    golfEventHeaderPhotos?: Array<GolfEvent> | null;
    golfEventProfilePhotos?: Array<GolfEvent> | null;
    golfers?: Array<Golfer> | null;
    organizerHeaderPhotos?: Array<Organizer> | null;
    organizerProfilePhotos?: Array<Organizer> | null;
    printItems?: Array<PrintItem> | null;
    printTemplates?: Array<PrintTemplate> | null;
}
export namespace S3File {
}


