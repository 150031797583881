<nav class="bg-white shadow fixed top-0 z-50 w-full">
  <ng-container *ngIf="!this.isHome() && !this.searchPage(); else headerFullScreen;">
    <div class="w-full max-w-7xl mx-auto">
      <ui-main-header [(isMenu)]="this.isMenu" [(signInVisible)]="this.signInVisible"></ui-main-header>
    </div>
  </ng-container>
  <ng-template #headerFullScreen>
    <div class="w-full mx-auto">
      <ui-main-header [(isMenu)]="this.isMenu" [(signInVisible)]="this.signInVisible"></ui-main-header>
    </div>
  </ng-template>
</nav>
<div id="main" class="mt-16" (click)="closeMenu()">
  <div *ngIf="this.alertVisible" class="flex items-center gap-x-6 bg-red-500 px-6 py-0.5 sm:px-1.5 sm:before:flex-1">
    <p class="text-sm leading-6 text-white">
      {{this.alertMessage}}
    </p>
    <div class="flex flex-1 justify-end">
      <button type="button" (click)="closeAlert()" class="-m-3 p-3 focus-visible:outline-offset-[-4px]">
        <span class="sr-only">Dismiss</span>
        <i class="fa-regular fa-times text-white"></i>
      </button>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
<footer class="bg-gray-100 border-gray-200 border-t sticky top-[calc(100vh-4em)]" (click)="closeMenu()">
  <ng-container *ngIf="!this.isHome() && !this.searchPage(); else footerFullScreen;">
    <div class="w-full max-w-7xl mx-auto">
      <ui-main-footer></ui-main-footer>
    </div>
  </ng-container>
  <ng-template #footerFullScreen>
    <div class="w-full mx-auto">
      <ui-main-footer></ui-main-footer>
    </div>
  </ng-template>
</footer>
<app-login-dialog [(signInVisible)]="this.signInVisible" [(signUpVisible)]="this.signUpVisible" [(forgotPasswordVisible)]="this.forgotPasswordVisible"></app-login-dialog>
<app-signup-dialog [(signUpVisible)]="this.signUpVisible"></app-signup-dialog>
<app-password-reset [(isVisible)]="this.forgotPasswordVisible"></app-password-reset>

<ui-modal [isVisible]="authCallbackMessageVisible" title="Authentication Message">
  <div [ngClass]="{ 'error-box': authCallbackIsError, 'success-box': !authCallbackIsError }">
    <p>{{authCallbackMessage}}</p>
  </div>
</ui-modal>
