import { Injectable } from '@angular/core';
import { AdminPermission } from '@app/models/admin-permission.interface';
import { AdminPermissionService, RpcHasAdminPermissionService, RpcIsAdminService } from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminHelper {

  constructor(
    private service: AdminPermissionService,
    private hasPermissionFunction: RpcHasAdminPermissionService,
    private isAdminFunction: RpcIsAdminService,
  ) { }

  hasEventWrite(): Observable<boolean> {
    return this.hasPermissionFunction.rpcHasAdminPermissionPost({
      args: {
        check_section: 'events',
        check_action: 'write'
      }
    });
  }

  hasAdminWrite(): Observable<boolean> {
    return this.hasPermissionFunction.rpcHasAdminPermissionPost({
      args: {
        check_section: 'admin',
        check_action: 'write'
      }
    });
  }

  isAdmin(): Observable<boolean> {
    return this.isAdminFunction.rpcIsAdminPost({ 
      args: {} 
    });
  }

  getAllPermissions(): Observable<AdminPermission[]> {
    return this.service.adminPermissionGet({});
  }
  createPermission(permission: AdminPermission): Observable<boolean> {
    return this.service.adminPermissionPost({
      adminPermission: permission
    }, 'response')
    .pipe(map(r => r.ok));
  }
  updatePermission(permission: AdminPermission): Observable<boolean> {
    return this.service.adminPermissionPatch({
      userId: permission.user_id,
      permissionSection: permission.permission_section,
      adminPermission: permission
    }, 'response')
    .pipe(map(r => r.ok));
  }
  deletePermission(permission: AdminPermission): Observable<boolean> {
    return this.service.adminPermissionDelete({
      userId: permission.user_id,
      permissionSection: permission.permission_section
    }, 'response')
    .pipe(map(r => r.ok));
  }

  fromPgArray(val: string): Array<string> {
    return val.replace('{', '').replace('}', '').split(',');
  }

  toPgArray(actions: Array<string>): string {
    return `{${actions.join(',')}}`;
  }
}
