/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 11.2.0 (c820efb)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Organizer { 
    /**
     * Note: This is a Primary Key.<pk/>
     */
    id: string;
    created_date: string;
    display_name?: string;
    contact_email?: string;
    contact_phone?: string;
    /**
     * Note: This is a Foreign Key to `s3_file.id`.<fk table=\'s3_file\' column=\'id\'/>
     */
    profile_photo_id?: string;
    /**
     * Note: This is a Foreign Key to `s3_file.id`.<fk table=\'s3_file\' column=\'id\'/>
     */
    header_photo_id?: string;
}

