<ng-container *ngrxLet="event$ as event">
  <ng-container *ngIf="event">
    <p class="text-xl text-gray-900">
      {{event.display_name}}
    </p>
    <p class="text-gray-400">Date: {{ event.event_datetime | date:'fullDate' }} {{ event.event_datetime | date:'shortTime' }}</p>
    <p class="text-gray-400">Location: {{ event.golf_course.display_name }} <br />{{ event.golf_course.address }} <!--<br /> {{ event.golf_course?.city }}, {{ event.golf_course?.state }} {{ event.golf_course?.postal_code }}--> </p>
  </ng-container>
</ng-container>
<ng-container *ngrxLet="package$ as package">
  <ng-container *ngIf="package">
    <p class="text-xl text-gray-900">
      <!--
      <span class="text-xs font-extralight inline-block uppercase rounded text-gray-600 last:mr-0 mr-1 -mb-2">
        {{package.category}} Package
      </span><br />
      -->
      {{package.display_name}}
    </p>
    <h3 *ngIf="showPrice" class="text-2xl">{{package.price}}</h3>
    <p class="text-gray-400">
      {{package.description}}
    </p>
    <ul class="mt-4">
      <ng-container *ngIf="package.event_package_contents !== undefined && package.event_package_contents.length > 0">
        <ng-container *ngFor="let event_package_content of package.event_package_contents">
          <ng-container *ngIf="event_package_content.event_package_reward_type !== undefined && event_package_content.qty !== undefined">
            <li class="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0" *ngIf="event_package_content.qty > 0"><i class="fa-light fa-arrow-right mr-2"></i> <span class="bg-gray-200 rounded-full text-gray-700 mr-1 px-1.5 text-center">{{event_package_content.qty}}</span> {{event_package_content.event_package_reward_type.display_name}}{{event_package_content.qty > 1 ? 's' : ''}}</li>
          </ng-container>
        </ng-container>
      </ng-container>
    </ul>
  </ng-container>
</ng-container>