import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-signup-dialog',
  templateUrl: './signup.dialog.html',
  animations: [ 
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('200ms ease-out', style({  opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class SignupDialog {
  @Input() signUpVisible: boolean = false;
  @Output() signUpVisibleChange = new EventEmitter<boolean>();
  signupForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    display_name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    confirmPassword: new FormControl('', Validators.required)
  }, { validators: this.checkPasswords });
  errorMessage?: string;
  success: boolean = false;

  constructor(
    private authService: AuthService,
  ) {}

  toggleModal(){
    this.signUpVisible = !this.signUpVisible;
    this.signUpVisibleChange.emit(this.signUpVisible);
  }

  create() {
    this.errorMessage = undefined;
    this.authService.signup(
      this.signupForm.get('email')?.value,
      this.signupForm.get('password')?.value,
      {
        phone: this.signupForm.get('phone')?.value,
        name: this.signupForm.get('name')?.value,
        create_profile: {
          display_name: this.signupForm.get('display_name')?.value
        }
      }
    ).subscribe(success => {
      this.success = success;
      if(!success) {
        this.errorMessage = "Signup failed"
      }
    }, error => {
      console.log(error)
      this.errorMessage = error.error?.error_description ?? "Sign Up failed";
    });
  }

  checkPasswords (group: AbstractControl):  ValidationErrors | null { 
    let pass = group.get('password')?.value;
    let confirmPass = group.get('confirmPassword')?.value
    return pass === confirmPass ? null : { notSame: true }
  }
}
