<div class="relative z-10" role="dialog" aria-modal="true">
  <div @AnimationTrigger0 *ngIf="this.isVisible" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
    <div @AnimationTrigger1 *ngIf="this.isVisible" class="pointer-events-auto relative max-w-2xl">
      <div @AnimationTrigger0 *ngIf="this.isVisible" class="absolute left-0 top-16 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
        <button (click)="this.toggleSlideOver()" type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
          <span class="sr-only">Close panel</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div *ngIf="this.isVisible" class="h-full overflow-y-auto bg-white p-8">
        <div class="divide-y divide-gray-200">
          <div class="pb-6">
            <div class="h-24 sm:h-20 lg:h-28"></div>
            <div class="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-16">
              <div>
                <div class="-m-1 flex">
                  <div class="inline-flex overflow-hidden rounded-lg border-4 border-white">
                    <span class="h-24 w-24 rounded-md ring-4 ring-white sm:h-32 sm:w-32 overflow-hidden ">
                      <i class="bg-green-50 fa-solid fa-golf-flag-hole text-8xl sm:text-9xl p-2 text-green-500"></i>
                      </span>
                  </div>
                </div>
              </div>
              <div class="mt-6 sm:ml-6 sm:flex-1">
                <div>
                  <div class="flex items-center">
                    <h3 class="text-xl font-bold text-gray-900 sm:text-2xl">{{course.display_name}} <i class="fa-solid fa-badge-check text-green-600"></i></h3>
                  </div>
                </div>
                <div class="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                  <button [routerLink]="'/courses/detail/' + course.id" [queryParams]="{back: this.backUrl}" type="button" class="inline-flex w-full flex-1 items-center justify-center rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">View Course</button>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 py-5 sm:px-0 sm:py-0">
            <dl class="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <div class="sm:flex sm:px-6 sm:py-5">
                <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">Location</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">{{course.address}}<br />{{ course.city }}, {{course.state}} {{course.postal_code}}</dd>
              </div>
              <div class="sm:flex sm:px-6 sm:py-5">
                <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">Phone</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                  {{course.phone_number}}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>