<div @opacity *ngIf="this.signInVisible" class="relative z-10" aria-labelledby="modal-title" role="dialog"
    aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <div @opacityTranslateY *ngIf="this.signInVisible"
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                    <div class="mt-3 text-center sm:mt-5">
                        <h2 class="text-2xl font-thin leading-6 text-green-500 uppercase" id="modal-title">Sign In</h2>
                        <div class="mt-2">
                            <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                                <div *ngIf="errorMessage" class="error-box">
                                    <p>{{ errorMessage }}</p>
                                </div>
                                <div *ngIf="notConfirmed?.message" class="warning-box">
                                    <p>{{ notConfirmed?.message }} <a *ngIf="!notConfirmed?.resent" (click)="resendConfirmation()"><b>Resend Confirmation?</b></a></p>
                                </div>
                                <form class="space-y-6" [formGroup]="loginForm">
                                    <div>
                                        <div class="flex items-center justify-between">
                                            <label for="email" class="label">Email</label>
                                            <div class="text-sm"></div>
                                        </div>
                                        <div class="mt-2">
                                            <input formControlName="email" id="email" name="email" type="email"
                                                autocomplete="email" required class="input">
                                            <ng-container *ngIf="loginForm.controls['email'] as ctl">
                                                <div *ngIf="ctl.invalid && (ctl.dirty || ctl.touched)"
                                                    class="alert">
                                                    <div *ngIf="ctl.errors?.['required']">
                                                        Email is required.
                                                    </div>
                                                    <div *ngIf="ctl.errors?.['email']">
                                                        Email must be a valid address.
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="flex items-center justify-between">
                                            <label for="password" class="label">Password</label>
                                            <div class="text-sm">
                                                <a (click)="openForgotPasswordDialog()" class="text-gray-400 hover:text-gray-600">Forgot
                                                    password?</a>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <input formControlName="password" id="password" name="password"
                                                type="password" autocomplete="current-password" required class="input">
                                                <ng-container *ngIf="loginForm.controls['password'] as ctl">
                                                    <div *ngIf="ctl.invalid && (ctl.dirty || ctl.touched)"
                                                        class="alert">
                                                        <div *ngIf="ctl.errors?.['required']">
                                                            Password is required.
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                    </div>
                                    <div>
                                        <button type="submit" class="submit" (click)="login()"
                                            [disabled]="!loginForm.valid">Sign in</button>
                                    </div>
                                </form>
                                <p class="mt-10 text-center text-sm text-gray-500">
                                    Don't have an account? <a
                                        class="font-semibold leading-6 text-green-500 hover:text-green-600"
                                        (click)="this.openSignUpDialog()">Sign Up</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-6">
                    <button type="button" (click)="this.toggleModal()"
                        class="close">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>