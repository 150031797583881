import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationWrapperComponent } from './navigation-wrapper/navigation-wrapper.component';
import { HeadModule } from '@app/components/head/head.module';
import { UiModule } from '@app/components/ui/ui.module';
import { BlogUiModule } from '@app/components/blog-ui/blog-ui.module';
import { LandingModule } from './landing/landing.module';
import { Configuration as CoreConfiguration, ApiModule as CoreApiModule } from '@lib/core';
import { Configuration as FinancialConfiguration, ApiModule as FinancialApiModule } from '@lib/financial';
import { Configuration as UtilityConfiguration, ApiModule as UtilityApiModule } from '@lib/utility';
import { AuthService } from './services/auth.service';
import { environment } from 'environments/environment';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { LoginDialog } from './navigation-wrapper/login-dialog/login.dialog';
import { SignupDialog } from './navigation-wrapper/signup-dialog/signup.dialog';
import { LetDirective } from '@ngrx/component';
import { PasswordResetDialog } from './navigation-wrapper/password-reset/password-reset.dialog';
import { provideEnvironmentNgxMask } from 'ngx-mask';

@NgModule({
  declarations: [
    AppComponent,
    NavigationWrapperComponent,
    LoginDialog,
    SignupDialog,
    PasswordResetDialog,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HeadModule,
    UiModule,
    BlogUiModule,
    LandingModule,
    CoreApiModule,
    FinancialApiModule,
    UtilityApiModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    LetDirective,
  ],
  providers: [
    provideClientHydration(),
    {
      provide: CoreConfiguration,
      useFactory: (authService: AuthService) => new CoreConfiguration(
        {
          basePath: environment.apiUrl,
          credentials: { JWT: authService.getAccessToken.bind(authService) }
        }
      ),
      deps: [AuthService],
      multi: false
    },
    {
      provide: FinancialConfiguration,
      useFactory: (authService: AuthService) => new FinancialConfiguration(
        {
          basePath: environment.financialUrl,
          credentials: { Bearer: authService.getAccessToken.bind(authService) }
        }
      ),
      deps: [AuthService],
      multi: false
    },
    {
      provide: UtilityConfiguration,
      useFactory: (authService: AuthService) => new UtilityConfiguration(
        {
          basePath: environment.utilityUrl,
          credentials: { Bearer: authService.getAccessToken.bind(authService) }
        }
      ),
      deps: [AuthService],
      multi: false
    },
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
