import { Component, Input, Output, EventEmitter } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ui-call-to-action',
  templateUrl: './call-to-action.component.html'
})
export class UiCallToActionComponent {
  private key$:BehaviorSubject<string> = new BehaviorSubject<string>('');

  @Input('key') set key(key: string) {
    this.setKey(key);
    this.keyChange.emit(key);
  }
  @Output() keyChange = new EventEmitter<string>();

  @Input() header: string = '';
  @Input() content: string = '';
  @Input() link: string = '';

  constructor(
  ) {
    this.key$.subscribe((key:string) => {
      console.log('key', key);
      this.getContent(key);
    });
  }

  private setKey(input:string){
    this.key$.next(input);
  }

  getContent(key:string = this.key) {
    if(key == 'Golfers'){ // Golfers
      this.header = 'Start Golfing Today';
      this.content = 'Find events nearby and start golfing for a cause!';
      this.link = '/search';
    } else if(key == 'Organizers'){ // Organizers
      this.header = 'Start an Event';
      this.content = 'Start organizing your own golf event today!';
      this.link = '/events/create';
    } else if(key == 'Sponsors'){ // Sponsors
      this.header = 'Find Sponsor Opportunities';
      this.content = 'Find events nearby to start sponsoring a cause!';
      this.link = '/search';
    }
  }
}
