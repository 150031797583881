<ng-container *ngIf="event">  
  <div class="relative w-full">
    <div class="relative h-56 overflow-hidden rounded-lg md:h-96">
      <ng-container *ngFor="let image of this.possibleImages">
        <img [src]="image" class="transition-all ease-in-out duration-150 absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" [ngClass]="image !== this.currentImage ? 'hidden' :''" alt="">
      </ng-container>
    </div>
    <div class="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
      <ng-container *ngFor="let image of this.possibleImages">
        <button type="button" class="w-3 h-3 rounded-full" [ngClass]="image === this.currentImage ? 'bg-white/75' : 'bg-white/25'" (click)="this.setCurrentImage(image)"></button>
      </ng-container>
    </div>
    <button type="button" class="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" (click)="this.prev()">
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50  group-focus:ring-4 group-focus:ring-white  group-focus:outline-none">
          <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
          </svg>
          <span class="sr-only">Previous</span>
        </span>
    </button>
    <button type="button" class="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" (click)="this.next()">
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
            <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
            </svg>
            <span class="sr-only">Next</span>
        </span>
    </button>
  </div>
</ng-container>