<div @FadeInFast class="transition-opacity mb-8">
    <ng-container *ngIf="this.template == 1">
        <article class="relative isolate flex flex-col gap-8 lg:flex-row">
            <div class="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0 cursor-pointer" routerLink="/blog/post/{{this.post.slug}}">
                <ng-container *ngIf="this.post.featured_media; else noMedia">
                    <blog-wp-image [mediaId]="this.post.featured_media" size="medium" class="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"></blog-wp-image>
                    <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
                </ng-container>
                <ng-template #noMedia>
                    <img src="/assets/img/hero/blog/post.jpg" class="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover" />
                    <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
                </ng-template>
            </div>
            <div class="w-full">
                <div class="flex items-center gap-x-4 text-xs lg:mt-8">
                    <time [dateTime]="this.post.date | date:'medium'" class="text-gray-500">{{this.post.date | date:'MM/dd/yyyy'}}</time>
                    <a *ngFor="let category of this.post.embedded.categories" routerLink="/blog/category/{{category.slug}}" class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">{{ category.name }}</a>
                </div>
                <div class="group relative w-full">
                    <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                        <a routerLink="/blog/post/{{this.post.slug}}">
                        <span class="absolute inset-0"></span>
                        {{this.post.title.rendered}}
                        </a>
                    </h3>
                    <div class="mt-4 flex">
                        <div class="relative flex gap-x-2.5 text-sm leading-6 text-gray-700">
                        <img src="{{ this.post.embedded.author?.avatar_urls?.[48] }}" alt="" class="h-6 w-6 flex-none rounded-full bg-gray-50">
                        {{ this.post.embedded.author?.name }}
                        </div>
                    </div>
                    <div class="mt-4 text-sm leading-6 text-gray-600" [innerHTML]="this.post.excerpt.rendered"></div>
                </div>
            </div>
        </article>
    </ng-container>
    <ng-container *ngIf="this.template == 2">
        <article class="flex flex-col items-start justify-between" routerLink="/blog/post/{{this.post.slug}}">
            <div class="relative w-full">
                <ng-container *ngIf="this.post.featured_media; else noMedia">
                    <blog-wp-image [mediaId]="this.post.featured_media" size="medium" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"></blog-wp-image>
                </ng-container>
                <ng-template #noMedia>
                    <img src="/assets/img/hero/blog/post.jpg" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
                </ng-template>
                <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
            </div>
            <div class="max-w-xl">
              <div class="mt-8 flex items-center gap-x-4 text-xs">
                <time [dateTime]="this.post.date | date:'medium'" class="text-gray-500">{{this.post.date | date:'MM/dd/yyyy'}}</time>
                <a *ngFor="let category of this.post.embedded.categories" routerLink="/blog/category/{{category.slug}}" class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">{{ category.name }}</a>
              </div>
              <div class="group relative">
                <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a routerLink="/blog/post/{{this.post.slug}}">
                    <span class="absolute inset-0"></span>
                    {{this.post.title.rendered}}
                  </a>
                </h3>
                <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600" [innerHTML]="this.post.excerpt.rendered"></p>
              </div>
              <!--
              <div class="relative mt-8 flex items-center gap-x-4">
                <img src="{{ this.post.embedded.author?.avatar_urls?.[48] }}" alt="" class="h-10 w-10 rounded-full bg-gray-100">
                <div class="text-sm leading-6">
                  <p class="font-semibold text-gray-900">
                    <span class="absolute inset-0"></span>
                    {{ this.post.embedded.author?.name }}
                  </p>
                </div>
              </div>
              -->
            </div>
        </article>
    </ng-container>
</div>