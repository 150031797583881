import { Component, Input } from '@angular/core';
import { Link } from '@app/interfaces/link.interface';

@Component({
  selector: 'ui-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class UiBreadcrumbComponent {
  @Input() links:Link[] = [];

  constructor(
  ) { }
}
