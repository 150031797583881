import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-package-content-icon',
  templateUrl: './package-content-icon.component.html'
})
export class UiPackageContentIconComponent {
  @Input({required: true}) contentId!: string;

}
