<ng-container *ngIf="orders">
  <div>
    <table class="table">
      <colgroup>
        <col class="w-full sm:w-3/6">
        <col class="sm:w-2/6">
        <col class="sm:w-1/6">
      </colgroup>
      <thead>
        <tr>
          <th scope="col">Order</th>
          <th scope="col">Date Completed</th>
          <th scope="col" class="!text-right">Amount</th>
        </tr>
      </thead>
      <tbody>
          <ng-container *ngFor="let order of orders">
            <tr>
              <td>
                <ng-container *ngIf="order.event_package.category == 'sponsor'; else golferCategory;">
                  <div class="text-sm leading-6 text-gray-900"> {{ sponsorNames.get(order.purchaser_user_id) }} </div>
                </ng-container>
                <ng-template #golferCategory>
                  <div class="text-sm leading-6 text-gray-900"> {{ golferNames.get(order.purchaser_user_id) }} </div>
                </ng-template>
                <div class="mt-1 text-xs leading-5 text-gray-500">{{order.event_package.display_name}} <small class="uppercase">({{order.event_package.category}})</small></div>
              </td>
              <td>
                <div class="text-sm leading-6 text-gray-900">
                  {{ order.finalized_at | date: 'medium' }}
                </div>
              </td>
              <td class="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
                <div class="text-right text-sm leading-6 text-gray-900">{{order.total}}</div>
                <div *ngIf="order.additional_donation != '$0.00'" class="text-right mt-1 text-xs leading-5 text-gray-500">{{order.additional_donation}} additional donation</div>
              </td>
            </tr>
          </ng-container>
      </tbody>
      <tfoot>
          <tr *ngIf="this.getPackageTotalDonations(orders) != '$0.00'">
              <td></td>
              <th scope="row" class="py-5 pl-3 pr-1 text-right text-sm text-gray-500 sm:pr-0">Additional Donations</th>
              <td class="py-5 pl-3 pr-4  text-right text-sm text-gray-500 sm:pr-0">{{this.getPackageTotalDonations(orders)}}</td>
          </tr>
          <tr>
              <td></td>
              <th scope="row" class="py-5 pl-3 pr-1 text-right text-sm font-semibold text-gray-900 sm:pr-0">Total</th>
              <td class="py-5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">{{this.getPackageTotals(orders)}}</td>
          </tr>
        </tfoot>
      </table>
  </div>
</ng-container>

