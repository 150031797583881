import { Component, Input, Output, EventEmitter } from "@angular/core";
import { trigger, transition, style, animate } from '@angular/animations';
import { Organizer } from "@app/models/organizer.interface";
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'ui-profile-overlay',
  templateUrl: './profile-overlay.component.html',
  animations: [ 
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in-out', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease-in-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('AnimationTrigger1', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in-out', style({  transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('smAnimationTrigger1', [
    ])
  ]
})
export class UiProfileOverlayComponent {
  @Input() organizer:Organizer = {} as Organizer;
  @Input() backUrl:string = '';
  _isVisible: boolean = false;
  get isVisible(): boolean {
    return this._isVisible;
  }
  @Input('isVisible') set isVisible(isVisible: boolean) {
    this._isVisible = isVisible;
    this.isVisibleChange.emit(isVisible);
  }
  @Output() isVisibleChange = new EventEmitter<boolean>();
  isLoggedIn: boolean = false;

  constructor (
    private authService: AuthService,
  ){
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  toggleSlideOver(){
    this.isVisible = !this.isVisible;
    this.isVisibleChange.emit(this.isVisible);
  }
}

