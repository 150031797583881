import {
    Directive,
    EventEmitter,
    HostListener,
    Output,
    ElementRef,
  } from '@angular/core';
  
  @Directive({
    selector: '[outsideClick]',
  })
  export class OutsideClickDirective {
    @Output()
    outsideClick: EventEmitter<MouseEvent> = new EventEmitter();
  
    @HostListener('document:mousedown', ['$event'])
    onClick(event: MouseEvent): void {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            if (event.target instanceof HTMLElement) {
                if (event.target.classList.contains('trigger')) {
                    return;
                } else {
                    if (event.target.parentElement && event.target.parentElement.classList.contains('trigger')) { // parent
                        return;
                    } else {
                        if (event.target.parentElement && event.target.parentElement.parentElement && event.target.parentElement.parentElement.classList.contains('trigger')) { // grandparent
                            return;
                        }
                    }
                }
            }
            this.outsideClick.emit(event);
        }
    }
    constructor(private elementRef: ElementRef) {}
  }