/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 11.2.0 (c820efb)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GolfEvent { 
    /**
     * Note: This is a Primary Key.<pk/>
     */
    id: string;
    /**
     * Note: This is a Foreign Key to `organizer.id`.<fk table=\'organizer\' column=\'id\'/>
     */
    owner_id: string;
    created_date: string;
    display_name: string;
    event_datetime: string;
    description?: string;
    /**
     * Note: This is a Foreign Key to `golf_course.id`.<fk table=\'golf_course\' column=\'id\'/>
     */
    golf_course_id: string;
    is_private: boolean;
    /**
     * Note: This is a Foreign Key to `s3_file.id`.<fk table=\'s3_file\' column=\'id\'/>
     */
    profile_photo_id?: string;
    /**
     * Note: This is a Foreign Key to `s3_file.id`.<fk table=\'s3_file\' column=\'id\'/>
     */
    header_photo_id?: string;
    active?: boolean;
    setup_step: number;
}

