import { PackageOrderGetRequestParams } from "@lib/core";

export interface PackageOrderDefaultParams extends PackageOrderGetRequestParams { }
export class PackageOrderDefaultParams {
  constructor() {
    this.select = "*,\
      golf_event(*,golf_course(*)),\
      event_package(*,event_package_contents(id,qty,order,event_package_reward_type(id,display_name))),\
      event_tickets:event_ticket(*,golfer(*)),\
      print_items:print_item(*,s3_file(path,thumbnail_path),\
        print_template(*,\
          event_package_contents(*,\
            event_package_reward_type(*)),\
        s3_file(path,thumbnail_path)))";
    this.finalizedAt = 'not.is.null';
  }
}