import { style, animate, trigger, transition } from '@angular/animations';

export const FadeInFast = trigger('FadeInFast', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('150ms ease-in-out', style({  opacity: 1 }))
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('150ms ease-in-out', style({ opacity: 0 }))
    ])
]);

export const FadeIn = trigger('FadeIn', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('300ms ease-in-out', style({  opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('300ms ease-in-out', style({ opacity: 0 }))
  ])
]);

export const OpacityScale = trigger('OpacityScale', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(.95)' }),
    animate('200ms ease-out', style({  opacity: 1, transform: 'scale(1)' }))
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'scale(1)' }),
      animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
  ])
]);

export const FlyOut = trigger('FlyOut', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(-1em)' }),
    animate('200ms ease-out', style({  opacity: 1, transform: 'translateY(0)' }))
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0)' }),
    animate('100ms ease-in', style({ opacity: 0, transform: 'translateY(-2em)' }))
  ])
]);