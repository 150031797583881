<div>
  <div class="sm:hidden">
    <label for="tabs" class="sr-only">Select a tab</label>
    <select (change)="this.onMenuChange($event)" id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm">
      <option [value]="'/events/edit/' + eventId + '/packages/edit/' + packageId" [selected]="this.isActive('/events/edit/' + eventId)">General</option>
      <option [value]="'/events/edit/' + eventId + '/packages/edit/' + packageId + '/contents'" [selected]="this.isActive('/events/edit/' + eventId + '/packages')">Package Items</option>
    </select>
  </div>
  <div class="hidden sm:block">
    <div class="flex h-14 justify-between">
      <nav class="hidden sm:flex sm:items-center sm:space-x-4">
        <a [routerLink]="'/events/edit/' + eventId + '/packages/edit/' + packageId" routerLinkActive="bg-green-500 text-white" [routerLinkActiveOptions]="{exact: true}"  class="rounded-md px-3 py-2 text-sm font-medium hover:bg-green-50"><i class="fa-light fa-layer-group"></i> General</a>
        <a [routerLink]="'/events/edit/' + eventId + '/packages/edit/' + packageId + '/contents'" routerLinkActive="bg-green-500 text-white" class="rounded-md px-3 py-2 text-sm font-medium hover:bg-green-50"><i class="fa-light fa-layer-plus"></i>  Package Items</a>
      </nav>
    </div>
  </div>
</div>
    