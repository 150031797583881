/**
 * Utility
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IGeometryObject } from './iGeometryObject';
import { GeoJSONObjectType } from './geoJSONObjectType';
import { ICRSObject } from './iCRSObject';


export interface Feature { 
    bbox?: Array<number> | null;
    crs?: ICRSObject;
    type?: GeoJSONObjectType;
    id?: string | null;
    geometry?: IGeometryObject;
    properties?: { [key: string]: any; } | null;
}
export namespace Feature {
}


