<div @opacity *ngIf="this.isVisible" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
      <div @opacityTranslateY *ngIf="this.isVisible" class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h2 class="text-2xl font-thin leading-6 text-green-500 uppercase" id="modal-title">{{this.title}}</h2>
            <div class="mt-2">
              <ng-content></ng-content>
            </div>
          </div>
        </div>
        <div *ngIf="showCloseButton" class="mt-5 sm:mt-6">
          <button type="button" (click)="this.toggleModal()" class="close">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
  
  