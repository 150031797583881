import { Component, Input } from "@angular/core";
import { Organizer } from '@app/models/organizer.interface';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ui-profile-header',
  templateUrl: './profile-header.component.html',
})
export class UiProfileHeaderComponent {
  @Input() organizer:Organizer = {} as Organizer;
  backUrl:string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { 
    this.route.queryParams.subscribe((params) => {
      if(params['back']) {
        this.backUrl = params['back'];
      }
    });
  }

  goBack() {    
    this.router.navigate([this.backUrl]);
  }
}

