<div @opacity *ngIf="this.signUpVisible" class="relative z-10" aria-labelledby="modal-title" role="dialog"
    aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <div @opacityTranslateY *ngIf="this.signUpVisible"
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                    <div class="mt-3 text-center sm:mt-5">
                        <h2 class="text-2xl font-thin leading-6 text-green-500 uppercase" id="modal-title">Sign Up</h2>
                        <div class="mt-2">
                            <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                                <form class="space-y-6" [formGroup]="signupForm">
                                    <!-- Public Fields -->
                                    <div class="border p-4 rounded-md border-gray-300 bg-gray-50 shadow">
                                        <p class="text-gray-400 mb-2"><i class="fa-light fa-globe"></i> Public</p>
                                        <div>
                                            <div class="flex items-center justify-between">
                                                <label for="display_name" class="label">Golfer Display Name</label>
                                                <div class="text-sm"></div>
                                            </div>
                                            <div class="mb-2">
                                                <input formControlName="display_name" id="display_name" name="display_name" type="text"
                                                    autocomplete="display_name" required class="input">
                                                <ng-container *ngIf="signupForm.controls['display_name'] as ctl">
                                                    <div *ngIf="ctl.invalid && (ctl.dirty || ctl.touched)"
                                                        class="alert">
                                                        <div *ngIf="ctl.errors?.['required']">
                                                            Display Name is required.
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Private Fields -->
                                    <div class="border p-4 rounded-md border-gray-200 bg-gray-50 shadow">
                                        <p class="text-gray-400 mb-2"><i class="fa-light fa-lock"></i> Private</p>
                                        <div>
                                            <div class="flex items-center justify-between">
                                                <label for="name" class="label">Full Name</label>
                                                <div class="text-sm"></div>
                                            </div>
                                            <div class="mb-2">
                                                <input formControlName="name" id="name" name="name" type="text"
                                                    autocomplete="name" required class="input">
                                                <ng-container *ngIf="signupForm.controls['name'] as ctl">
                                                    <div *ngIf="ctl.invalid && (ctl.dirty || ctl.touched)"
                                                        class="alert">
                                                        <div *ngIf="ctl.errors?.['required']">
                                                            Full Name is required.
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <app-form-input-phone
                                            [form]="signupForm" 
                                            controlName="phone" 
                                            label="Contact Phone Number" 
                                        />
                                        <div>
                                            <div class="flex items-center justify-between">
                                                <label for="email" class="label">Email</label>
                                                <div class="text-sm"></div>
                                            </div>
                                            <div class="mb-2">
                                                <input formControlName="email" id="email" name="email" type="email"
                                                    autocomplete="email" required class="input">
                                                <ng-container *ngIf="signupForm.controls['email'] as ctl">
                                                    <div *ngIf="ctl.invalid && (ctl.dirty || ctl.touched)"
                                                        class="alert">
                                                        <div *ngIf="ctl.errors?.['required']">
                                                            Email is required.
                                                        </div>
                                                        <div *ngIf="ctl.errors?.['email']">
                                                            Email must be a valid address.
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="flex items-center justify-between">
                                                <label for="password" class="label">Password</label>
                                                <div class="text-sm"></div>
                                            </div>
                                            <div class="mb-2">
                                                <input formControlName="password" id="password" name="password"
                                                    type="password" required class="input">
                                                <ng-container *ngIf="signupForm.controls['password'] as ctl">
                                                    <div *ngIf="ctl.invalid && (ctl.dirty || ctl.touched)"
                                                        class="alert">
                                                        <div *ngIf="ctl.errors?.['required']">
                                                            Password is required.
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="flex items-center justify-between">
                                                <label for="confirmPassword" class="label">Confirm Password</label>
                                                <div class="text-sm"></div>
                                            </div>
                                            <div class="mb-2">
                                                <input formControlName="confirmPassword" id="confirmPassword"
                                                    name="confirmPassword" type="password" required class="input">
                                                <ng-container *ngIf="signupForm.controls['confirmPassword'] as ctl">
                                                    <div *ngIf="ctl.invalid && (ctl.dirty || ctl.touched)"
                                                        class="alert">
                                                        <div *ngIf="ctl.errors?.['required']">
                                                            Password is required.
                                                        </div>
                                                    </div>
                                                    <div *ngIf="signupForm.invalid && (ctl.dirty || ctl.touched)"
                                                        class="alert">
                                                        <div *ngIf="signupForm.errors?.['notSame']">
                                                            Passwords must match.
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="success" class="success-box">
                                        <p>Sign Up was successful. Check your email to verify your account.</p>
                                    </div>
                                    <div *ngIf="errorMessage" class="error-box">
                                        <p>{{ errorMessage }}</p>
                                    </div>
                                    <div>
                                        <button type="submit" class="submit" (click)="create()"
                                            [disabled]="!signupForm.valid">Sign Up</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-6">
                    <button type="button" (click)="this.toggleModal()"
                        class="close">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>