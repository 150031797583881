<div class="mx-auto px-6 pb-8 pt-16 sm:pt-24 lg:px-8">
  <div class="xl:grid xl:grid-cols-3 xl:gap-8">
    <div class="space-y-8">
      <a routerLink="/" class="flex items-center mt-1">
        <span class="self-center text-2xl whitespace-nowrap brand">my<span>golf</span>events</span>
        <small class="relative left-1 top-0.5 inline-flex items-center gap-x-1.5 rounded-full bg-orange-200 px-1.5 py-0.5 text-[8px] font-medium text-orange-600 border border-orange-500">
          alpha
        </small>
      </a>
      <p class="text-sm leading-6 text-gray-400">Better golf events, for everyone.</p>
      <!--
      <div class="flex space-x-6">
        <a href="#" target="_blank" class="text-gray-400 hover:text-gray-500">
          <i class="fa-brands fa-facebook text-lg"></i>
        </a>
        <a href="#" target="_blank" class="text-gray-400 hover:text-gray-500">
          <i class="fa-brands fa-instagram text-lg"></i>
        </a>
        <a href="#" target="_blank" class="text-gray-400 hover:text-gray-500">
          <i class="fa-brands fa-twitter text-lg"></i>
        </a>
        <a href="#" target="_blank" class="text-gray-400 hover:text-gray-500">
          <i class="fa-brands fa-tiktok text-lg"></i>
        </a>
        <a href="#" target="_blank" class="text-gray-400 hover:text-gray-500">
          <i class="fa-brands fa-youtube text-lg"></i>
        </a>
      </div>
      -->
    </div>
    <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
      <div class="md:grid md:grid-cols-2 md:gap-8">
        <div>
          <h3 class="text-sm font-semibold leading-6 text-gray-500">Solutions</h3>
          <ul role="list" class="mt-6 space-y-4">
            <li>
              <a routerLink="/solutions/golfers" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Golfers</a>
            </li>
            <li>
              <a routerLink="/solutions/organizers" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Organizers</a>
            </li>
            <li>
              <a routerLink="/solutions/sponsors" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Sponsors</a>
            </li>
          </ul>
        </div>
        <div>
          <!--
          <ul role="list" class="mt-6 space-y-4">
            <li>
              <a routerLink="/solutions/golf-courses" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Golf Courses</a>
            </li>
            <li>
              <a routerLink="/solutions/golf-pros" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Golf Pros</a>
            </li>
            <li>
              <a routerLink="/solutions/beneficiaries" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Beneficiaries</a>
            </li>
          </ul>
          -->
          <h3 class="text-sm font-semibold leading-6 text-gray-500">Company</h3>
          <ul role="list" class="mt-6 space-y-4">
            <li>
              <a routerLink="/about" class="text-sm leading-6 text-gray-400 hover:text-gray-900">About</a>
            </li>
            <li>
              <a routerLink="/pricing" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Pricing</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="md:grid md:grid-cols-2 md:gap-8">
        <div class="mt-0 md:mt-6">
          <ul role="list" class="mt-6 space-y-4">
            <li>
              <a routerLink="/blog" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Blog</a>
            </li>
            <li>
              <a routerLink="/press" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Press</a>
            </li>
          </ul>
        </div>
        <div class="mt-0 md:mt-6">
          <ul role="list" class="mt-6 space-y-4">
            <li>
              <a routerLink="/contact" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Contact</a>
            </li>
            <li>
              <a routerLink="/contact/support" class="text-sm leading-6 text-gray-400 hover:text-gray-900">Support Center</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
    <p class="text-xs float-right text-gray-200">
      <a routerLink="/about/privacy-policy" class="text-xs leading-6 text-gray-400 hover:text-gray-600">Privacy</a> | 
      <a routerLink="/about/terms-of-service" class="text-xs leading-6 text-gray-400 hover:text-gray-600">Terms</a> |
      <a routerLink="/about/refund-policy" class="text-xs leading-6 text-gray-400 hover:text-gray-600">Refunds</a>
    </p>
    <p class="text-xs leading-5 text-gray-500">&copy; 2023{{this.getCurrentYear()}} My Golf Events. All rights reserved.</p>
  </div>
</div>