/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 11.2.0 (c820efb)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PrintItem { 
    /**
     * Note: This is a Primary Key.<pk/>
     */
    id: string;
    /**
     * Note: This is a Foreign Key to `package_order.id`.<fk table=\'package_order\' column=\'id\'/>
     */
    package_order_id: string;
    /**
     * Note: This is a Foreign Key to `print_template.id`.<fk table=\'print_template\' column=\'id\'/>
     */
    print_template_id: string;
    editor_state?: object;
    /**
     * Note: This is a Foreign Key to `s3_file.id`.<fk table=\'s3_file\' column=\'id\'/>
     */
    s3_file_id?: string;
}

