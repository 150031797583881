<ng-container *ngIf="event">
  <div class="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white cursor-pointer" [routerLink]="'/events/detail/' + event.id">
    <div class="aspect-h-3 aspect-w-4 bg-gray-200 sm:aspect-none group-hover:opacity-75 transition-all ease-in-out duration-150 sm:h-72 overflow-hidden">
        <img [src]="event.profile_photo?.thumbnail_path ?? '/assets/s3/01.jpg'" alt="" class="h-full w-full object-cover object-center sm:h-full sm:w-full group-hover:scale-105 transition-all ease-in-out duration-150">
    </div>
    <div class="flex flex-1 flex-col space-y-2 p-4">
        <h3 class="text-sm font-medium text-gray-900">
            <span aria-hidden="true" class="absolute inset-0"></span>
            {{ event.display_name }}
        </h3>
        <p class="text-sm text-gray-500">{{ event.event_datetime | date:'fullDate' }} {{ event.event_datetime | date:'shortTime' }}</p>
        <div class="flex flex-1 flex-col justify-end">
            <p class="text-base font-medium text-gray-900">{{ event.golf_course.display_name }}</p>
            <!--
            <p class="text-sm italic text-gray-500" [innerHTML]="event.description | slice:0:50"></p>
            -->
            <!-- {{ this.truncateOutput(event.description) }} -->
        </div>
    </div>
  </div>
</ng-container>