import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WPCategory, WPMedia, WPPost } from '@app/models/wordpress.interface';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private readonly url: string = environment.blogUrl + '?rest_route=/wp/v2';

  /*
  * API Reference: https://developer.wordpress.org/rest-api/reference/
  */

  constructor(
    private http: HttpClient,
  ) { }

  getPosts(perPage: number = 15, pageNumber: number = 1): Observable<WPPost[]> {
    return this.http.get(`${this.url}/posts&per_page=${perPage}&page=${pageNumber}&_embed=author,wp:term,wp:featuredmedia`)
      .pipe(map(x => <WPPost[]>x))
      .pipe(map(posts => posts.map(p => this.translateEmbeddings(p))));
  }
  getPostsByCategories(categoryId: number[],perPage: number = 15, pageNumber: number = 1): Observable<WPPost[]> {
    return this.http.get(`${this.url}/posts&categories=${categoryId.join(',')}&per_page=${perPage}&page=${pageNumber}&_embed=author,wp:term,wp:featuredmedia`)
      .pipe(map(x => <WPPost[]>x))
      .pipe(map(posts => posts.map(p => this.translateEmbeddings(p))));
  }
  getPost(postId:number):Observable<WPPost> {
    return this.http.get(`${this.url}/posts/${postId}&_embed=author,wp:term,wp:featuredmedia`)
      .pipe(map(x => <any[]>x))
      .pipe(map(x => <WPPost>x[0]))
      .pipe(map(post => this.translateEmbeddings(post)));
  }
  getPostBySlug(slug:string):Observable<WPPost> {
    return this.http.get(`${this.url}/posts&slug=${slug}&_embed=author,wp:term,wp:featuredmedia`)
      .pipe(map(x => <any[]>x))
      .pipe(map(x => <WPPost>x[0]))
      .pipe(map(post => this.translateEmbeddings(post)));
  }

  getPageBySlug(slug:string):Observable<WPPost> {
    return this.http.get(`${this.url}/pages&slug=${slug}`)
      .pipe(map(x => <any[]>x))
      .pipe(map(x => <WPPost>x[0]));
  }

  getMedia(mediaId: number): Observable<WPMedia> {
    return this.http.get(`${this.url}/media/${mediaId}`)
      .pipe(map(x => <WPMedia>x));
  }
  getCategoryBySlug(slug:string):Observable<WPCategory> {
    return this.http.get(`${this.url}/categories&slug=${slug}`)
    .pipe(map(x => <any[]>x))
    .pipe(map(x => <WPCategory>x[0]));
  }

  private translateEmbeddings(post: WPPost): WPPost {
    post.embedded = {};
    if(post._embedded?.author) {
      post.embedded.author = post._embedded?.author[0];
    }
    if(post._embedded['wp:featuredmedia']) {
      post.embedded.featuredMedia = post._embedded['wp:featuredmedia'][0];
    }
    if(post._embedded['wp:term']) {
      let categories = post._embedded['wp:term'][0];
      let tags = post._embedded['wp:term'][1];
      post.embedded.categories = categories;
    }
    return post;
  }
}
