import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModalComponent } from './modal/modal.component';
import { UiUserTabsComponent } from './user-tabs/user-tabs.component';
import { UiOrganizerTabsComponent } from './organizer-tabs/organizer-tabs.component';
import { UiNotLoggedInComponent } from './not-logged-in/not-logged-in.component';
import { RouterModule } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { AngularPinturaModule } from '@pqina/angular-pintura';
import { UiEventEditTabsComponent } from './event-tabs/event-tabs.component';
import { UiEventEditPackageTabsComponent } from './event-package-tabs/event-package-tabs.component'
import { UiBreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { UiPackagePurchaseComponent } from './package-purchase/package-purchase.component';
import { UiNewOrderListingComponent } from './new-order-listing/new-order-listing.component';
import { UiEventCardComponent } from './event-card/event-card.component';
import { UiProfileHeaderComponent } from './profile-header/profile-header.component';
import { UiCourseHeaderComponent } from './course-header/course-header.component';
import { UiCourseOverlayComponent } from './course-overlay/course-overlay.component';
import { UiProfileOverlayComponent } from './profile-overlay/profile-overlay.component';
import { UiNoDataPlaceholderComponent } from './no-data-placeholder/no-data-placeholder.component';
import { UiEventOrdersTableComponent } from './event-orders-table/event-orders-table.component';
import { UiSearchFilterComponent } from './search-filter/search-filter.component';
import { UiMainHeaderComponent } from './main-header/main-header.component';
import { UiMainFooterComponent } from './main-footer/main-footer.component';
import { UiEventCarouselComponent } from './event-carousel/event-carousel.component';
import { UiEventSponsorsComponent } from './event-sponsors/event-sponsors.component';
import { UiOrganizerChooserModal } from './organizer-chooser/organizer-chooser.component';
import { UiCallToActionComponent } from './call-to-action/call-to-action.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OutsideClickDirective } from "@app/components/directives";
import { UiUserContactChooserModal } from './user-contact-chooser/user-contact-chooser.component';
import { UiStepperComponent } from './stepper/stepper.component';
import { UiFormInputPhoneComponent } from './form-input-phone/form-input-phone.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { UiPackageContentIconComponent } from './package-content-icon/package-content-icon.component';

@NgModule({
  declarations: [
    OutsideClickDirective,
    UiBreadcrumbComponent,
    UiModalComponent,
    UiUserTabsComponent,
    UiOrganizerTabsComponent,
    UiEventEditTabsComponent,
    UiEventEditPackageTabsComponent,
    UiNotLoggedInComponent,
    UiPackagePurchaseComponent,
    UiNewOrderListingComponent,
    UiEventCardComponent,
    UiProfileHeaderComponent,
    UiCourseHeaderComponent,
    UiCourseOverlayComponent,
    UiProfileOverlayComponent,
    UiNoDataPlaceholderComponent,
    UiEventOrdersTableComponent,
    UiSearchFilterComponent,
    UiMainHeaderComponent,    
    UiMainFooterComponent,
    UiEventCarouselComponent,
    UiEventSponsorsComponent,
    UiOrganizerChooserModal,
    UiCallToActionComponent,
    UiUserContactChooserModal,
    UiStepperComponent,
    UiFormInputPhoneComponent,
    UiPackageContentIconComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LetDirective,
    AngularPinturaModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [
    AngularPinturaModule,
    UiBreadcrumbComponent,
    UiModalComponent,
    UiUserTabsComponent,
    UiOrganizerTabsComponent,
    UiEventEditTabsComponent,
    UiEventEditPackageTabsComponent,
    UiNotLoggedInComponent,
    UiPackagePurchaseComponent,
    UiNewOrderListingComponent,
    UiEventCardComponent,
    UiProfileHeaderComponent,
    UiCourseHeaderComponent,
    UiCourseOverlayComponent,
    UiProfileOverlayComponent,
    UiNoDataPlaceholderComponent,
    UiEventOrdersTableComponent,
    UiSearchFilterComponent,
    UiMainHeaderComponent,
    UiMainFooterComponent,
    UiEventCarouselComponent,
    UiEventSponsorsComponent,
    UiOrganizerChooserModal,
    UiCallToActionComponent,
    UiUserContactChooserModal,
    UiStepperComponent,
    UiFormInputPhoneComponent,
    UiPackageContentIconComponent,
  ]
})
export class UiModule { }
