import { Component, Input } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { GolfCourse } from '@app/models/golf-course.interface';

@Component({
  selector: 'ui-course-header',
  templateUrl: './course-header.component.html',
})
export class UiCourseHeaderComponent {
  @Input() course:GolfCourse = {} as GolfCourse;
  backUrl:string = '';
  
  constructor (
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.queryParams.subscribe((params) => {
      if(params['back']) {
        this.backUrl = params['back'];
      }
    });
  }

  goBack() {    
    this.router.navigate([this.backUrl]);
  }
}

