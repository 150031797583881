import { Component } from "@angular/core";
import { Router } from '@angular/router';

@Component({
  selector: 'ui-user-tabs',
  templateUrl: './user-tabs.component.html',
})
export class UiUserTabsComponent {
  constructor(
    private router: Router
  ) { }

  onMenuChange(event:any){
    let url = event?.target?.value ?? null;
    if(url){
      this.router.navigateByUrl(url);
    }
  }

  isActive(url:string):boolean{
    return this.router.url === url;
  }
  
}

