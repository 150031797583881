<ui-modal title="Choose a new Organizer" [(isVisible)]="this.isVisible" (isVisibleChange)="modalOpened($event)">
  <div *ngIf="!searchResults$">
    <div class="text-center px-2 mb-4 text-gray-800" role="alert">
      <div class="text-sm font-medium ">
        Enter an Organizer's name, email, or phone and hit search to begin.
      </div>
    </div>
  </div>
  <form [formGroup]="searchForm" class="relative mb-4 flex w-full flex-wrap items-stretch">
      <input class="input relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-r-none" type="text" formControlName="searchText">
      <button class="relative z-[2] flex items-center rounded-r bg-green-500 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg" (click)="search()"> <i class="fa fa-search"></i></button>
  </form>
  <div class="space-y-4">
    <ng-container *ngrxLet="searchResults$ as results">
      <label *ngFor="let organizer of results" 
        [ngClass]="{ 'border-green-800 ring-2 ring-green-800': selectedId == organizer.id, 'border-gray-300': selectedId != organizer.id}" 
        class="relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
        (click)="selected(organizer.id, organizer.display_name)">
        <input type="radio" name="organizer_id" value="{{organizer.id}}" class="sr-only" />
        <span class="flex items-center">
          <span class="flex flex-col text-sm">
            <span id="organizer-label" class="font-medium text-gray-900">{{ organizer.display_name }}</span>
            <span id="organizer-description" class="text-gray-500">
              <span class="block sm:inline">{{ organizer.contact_email }}</span>
              <span class="hidden sm:mx-1 sm:inline" aria-hidden="true">&middot;</span>
              <span class="block sm:inline">{{ organizer.contact_phone ?? '' | mask: '(000) 000-0000' }}</span>
            </span>
          </span>
        </span>
        <span 
          [ngClass]="{ 'border-green-800': selectedId == organizer.id, 'border-transparent': selectedId != organizer.id}"
          class="pointer-events-none absolute -inset-px rounded-lg border-2" 
          aria-hidden="true"></span>
      </label>
    </ng-container>
  </div>
  <button class="submit" (click)="save()">
      Make "{{selectedName}}" the owner of "{{eventName}}"
  </button>
</ui-modal>
