/**
 * Financial
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NpgsqlPoint } from './npgsqlPoint';
import { GolfEvent } from './golfEvent';
import { S3File } from './s3File';


export interface GolfCourse { 
    id?: string;
    displayName?: string | null;
    address?: string | null;
    phoneNumber?: string | null;
    website?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    postalCode?: string | null;
    extRefId?: string | null;
    location?: NpgsqlPoint;
    profilePhotoId?: string | null;
    headerPhotoId?: string | null;
    timezone?: string | null;
    golfEvents?: Array<GolfEvent> | null;
    headerPhoto?: S3File;
    profilePhoto?: S3File;
}

