export * from './adminPermission.service';
import { AdminPermissionService } from './adminPermission.service';
export * from './eventContact.service';
import { EventContactService } from './eventContact.service';
export * from './eventFee.service';
import { EventFeeService } from './eventFee.service';
export * from './eventPackage.service';
import { EventPackageService } from './eventPackage.service';
export * from './eventPackageContents.service';
import { EventPackageContentsService } from './eventPackageContents.service';
export * from './eventPackageRewardFee.service';
import { EventPackageRewardFeeService } from './eventPackageRewardFee.service';
export * from './eventPackageRewardType.service';
import { EventPackageRewardTypeService } from './eventPackageRewardType.service';
export * from './eventTicket.service';
import { EventTicketService } from './eventTicket.service';
export * from './golfCourse.service';
import { GolfCourseService } from './golfCourse.service';
export * from './golfEvent.service';
import { GolfEventService } from './golfEvent.service';
export * from './golfer.service';
import { GolferService } from './golfer.service';
export * from './introspection.service';
import { IntrospectionService } from './introspection.service';
export * from './notificationPreference.service';
import { NotificationPreferenceService } from './notificationPreference.service';
export * from './organizer.service';
import { OrganizerService } from './organizer.service';
export * from './packageOrder.service';
import { PackageOrderService } from './packageOrder.service';
export * from './prepaidPackage.service';
import { PrepaidPackageService } from './prepaidPackage.service';
export * from './printItem.service';
import { PrintItemService } from './printItem.service';
export * from './printTemplate.service';
import { PrintTemplateService } from './printTemplate.service';
export * from './rpcCountGolfCoursesInRadius.service';
import { RpcCountGolfCoursesInRadiusService } from './rpcCountGolfCoursesInRadius.service';
export * from './rpcGetEventIdByTicketId.service';
import { RpcGetEventIdByTicketIdService } from './rpcGetEventIdByTicketId.service';
export * from './rpcGetEventTicketById.service';
import { RpcGetEventTicketByIdService } from './rpcGetEventTicketById.service';
export * from './rpcGetPrepaidPackageById.service';
import { RpcGetPrepaidPackageByIdService } from './rpcGetPrepaidPackageById.service';
export * from './rpcGetSponsorsByEventId.service';
import { RpcGetSponsorsByEventIdService } from './rpcGetSponsorsByEventId.service';
export * from './rpcGolfCourseSearch.service';
import { RpcGolfCourseSearchService } from './rpcGolfCourseSearch.service';
export * from './rpcHasAdminPermission.service';
import { RpcHasAdminPermissionService } from './rpcHasAdminPermission.service';
export * from './rpcIsAdmin.service';
import { RpcIsAdminService } from './rpcIsAdmin.service';
export * from './rpcIsEmailAllowedOnPrivateEvent.service';
import { RpcIsEmailAllowedOnPrivateEventService } from './rpcIsEmailAllowedOnPrivateEvent.service';
export * from './rpcOrganizerSearch.service';
import { RpcOrganizerSearchService } from './rpcOrganizerSearch.service';
export * from './rpcUserContactSearch.service';
import { RpcUserContactSearchService } from './rpcUserContactSearch.service';
export * from './s3File.service';
import { S3FileService } from './s3File.service';
export * from './sponsor.service';
import { SponsorService } from './sponsor.service';
export * from './stripeAccount.service';
import { StripeAccountService } from './stripeAccount.service';
export * from './userContactInformation.service';
import { UserContactInformationService } from './userContactInformation.service';
export const APIS = [AdminPermissionService, EventContactService, EventFeeService, EventPackageService, EventPackageContentsService, EventPackageRewardFeeService, EventPackageRewardTypeService, EventTicketService, GolfCourseService, GolfEventService, GolferService, IntrospectionService, NotificationPreferenceService, OrganizerService, PackageOrderService, PrepaidPackageService, PrintItemService, PrintTemplateService, RpcCountGolfCoursesInRadiusService, RpcGetEventIdByTicketIdService, RpcGetEventTicketByIdService, RpcGetPrepaidPackageByIdService, RpcGetSponsorsByEventIdService, RpcGolfCourseSearchService, RpcHasAdminPermissionService, RpcIsAdminService, RpcIsEmailAllowedOnPrivateEventService, RpcOrganizerSearchService, RpcUserContactSearchService, S3FileService, SponsorService, StripeAccountService, UserContactInformationService];
