import { Injectable } from '@angular/core';
import { EventPackageRewardTypeService, EventPackageContentsService, EventPackageService, EventPackageContentsGetRequestParams } from '@lib/core';
import { Observable, first, map } from 'rxjs';
import { EventPackage, EventPackageCreate } from '@app/models/event-package.interface';
import { EventPackageContents, EventPackageContentsCreate } from '@app/models/event-package-contents.interface';
import { EventPackageRewardType } from '@app/models/event-package-reward-type.interface';
import { EventPackageDefaultParams as DefaultParams } from '@app/models/event-package-default-params.class'

@Injectable({
  providedIn: 'root'
})
export class GolfEventPackageHelper {

  constructor(
    private service: EventPackageService,
    private contentService: EventPackageContentsService,
    private rewardTypesService: EventPackageRewardTypeService,
  ) { }

  getRewardTypes():Observable<EventPackageRewardType[]> {
    return this.rewardTypesService.eventPackageRewardTypeGet(new DefaultParams())
    .pipe(map(x => <EventPackageRewardType[]>x));
  }

  getPackageById(packageId: string):Observable<EventPackage> {
    let params = new DefaultParams();
    params.id = 'eq.' + packageId;
    return this.service.eventPackageGet(params)
      .pipe(map(array => array[0]))
      .pipe(first())
      .pipe(map(x => <EventPackage>x));
  }

  getPackagesByEventId(eventId: string):Observable<EventPackage[]> {
    let params = new DefaultParams();
    params.golfEventId = 'eq.' + eventId;
    params.order = 'category,price';
    return this.service.eventPackageGet(params);
  }

  createEventPackage(input: EventPackageCreate): Observable<string|null> {
    return this.service.eventPackagePost({
      eventPackage: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<EventPackage>body).id;
    }));
  }

  updateEventPackage(eventPackageId: string, input: EventPackageCreate): Observable<boolean> {
    return this.service.eventPackagePatch({
      id: 'eq.' + eventPackageId,
      eventPackage: <any>input
    }, 'response')
    .pipe(map(r => {
      return r.ok;
    }));
  }

  deleteEventPackage(eventPackageId: string): Observable<boolean> {
    return this.service.eventPackageDelete({
      id: 'eq.' + eventPackageId,
    }, 'response')
    .pipe(map(r => {
      return r.ok;
    }));
  }

  getContentsByPackageId(packageId: string):Observable<EventPackageContents[]> {
    //console.log('getContentsByPackageId('+packageId+')');
    let params = { 
      select: '*,event_package_reward_type(id,display_name,print_width_pixels,print_height_pixels),print_template(*,s3_file(*))',
      packageId: 'eq.' + packageId
    };
    return this.contentService.eventPackageContentsGet(params)
      .pipe(map(x => <EventPackageContents[]>x));
  }

  getPackageContentsById(eventPackageContentId: string):Observable<EventPackageContents> {
    let params = { 
      select: '*,print_template(*)',
      id: 'eq.' + eventPackageContentId
    };
    return this.contentService.eventPackageContentsGet(params)
    .pipe(map(array => array[0]))
    .pipe(first())
    .pipe(map(x => <EventPackageContents>x));
  }

  createEventPackageContents(input: EventPackageContentsCreate): Observable<string|null> {
    return this.contentService.eventPackageContentsPost({
      eventPackageContents: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<EventPackageContents>body).package_id;
    }));
  }

  updateEventPackageContents(eventPackageContentId: string, input: EventPackageContentsCreate): Observable<boolean> {
    return this.contentService.eventPackageContentsPatch({
      id: 'eq.' + eventPackageContentId,
      eventPackageContents: <any>input
    }, 'response')
    .pipe(map(r => {
      return r.ok;
    }));
  }

  deleteEventPackageContents(eventPackageContentsId: string): Observable<boolean> {
    return this.contentService.eventPackageContentsDelete({
      id: 'eq.' + eventPackageContentsId,
    }, 'response')
    .pipe(map(r => {
      return r.ok;
    }));
  }

  public static formattedDollarToNumber(dollars: string): number {
    return Number(dollars.replace(/[^0-9.-]+/g,""));
  }

  public static filterPackages(packages: EventPackage[], category: string) {
    return packages.filter(p => p.category == category);
  }

}
