import { Component, Input } from "@angular/core";
import { GolfEvent, EventSponsorWithPackage } from '@app/models/golf-event.interface';

import { Observable } from 'rxjs';
import { GolfEventHelper } from '@app/services/golf-event-helper.service';

@Component({
  selector: 'ui-event-sponsors',
  templateUrl: './event-sponsors.component.html'
})
export class UiEventSponsorsComponent {
  @Input() event: GolfEvent = {} as GolfEvent;

  sponsors$?: Observable<EventSponsorWithPackage[]>;

  constructor(
    private golfEventHelper: GolfEventHelper,
  ) {

  }

  ngOnInit() {
    if(this.event.id !== undefined){
      //console.log('golfEvent', this.event);
      this.sponsors$ = this.golfEventHelper.getSponsorshipsByEventId(this.event.id);
    }
  }
}
