import { Component } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { Router } from '@angular/router';
import { AuthVerifyResponse } from '@app/models/auth-models.interface';
import { trigger, transition, style, animate } from '@angular/animations';
import { AuthUser } from '@app/models/auth-models.interface';
import { Observable, timer } from 'rxjs';

@Component({
  selector: 'app-navigation-wrapper',
  templateUrl: './navigation-wrapper.component.html',
  styleUrls: ['./navigation-wrapper.component.scss'],
  animations: [ 
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('200ms ease-out', style({  opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ])
  ]
})
export class NavigationWrapperComponent {
  user$: Observable<AuthUser>;
  signInVisible: boolean = false;
  signUpVisible: boolean = false;
  forgotPasswordVisible: boolean = false;

  authCallbackMessageVisible: boolean = false;
  authCallbackMessage?: string;
  authCallbackIsError: boolean = false;
  isMenu = false;

  alertVisible = false;
  alertMessage = 'My Golf Events is experiencing some technical difficulties. Please check back later.';

  constructor(
    public authService: AuthService,
    private router: Router,
  ) {
    this.checkAuthCallback(router.url);
    this.user$ = authService.getUser();
  }

  closeAlert() {
    this.alertVisible = false;
  }

  closeMenu(){
    this.isMenu = false;
  }
  toggleMenu(){
    this.isMenu = !this.isMenu;
  }

  openSignInDialog() {
    this.authService.setRedirectUrl(this.router.url);
    this.signInVisible = true;
  }
  openForgotPasswordDialog() {
    this.signInVisible = false;
    this.forgotPasswordVisible = true;
  }

  openSignUpDialog() {
    this.signUpVisible = true;
  }

  logout() {
    this.authService.setRedirectUrl(this.router.url);
    this.authService.logout();
  }
  checkAuthCallback(url: string) {
    if(url.includes('#')) {
      let params = url.split('#')[1].split('&');
      if (url.includes('access_token') && url.includes('refresh_token') && url.includes('expires_in')) {
        let ret:AuthVerifyResponse = {
          access_token: this.findParam(params, 'access_token'),
          refresh_token: this.findParam(params, 'refresh_token'),
          expires_in: Number(this.findParam(params, 'expires_in')),
          type: this.findParam(params, 'type'),
          token_type: this.findParam(params, 'token_type')
        };
        this.authService.setTokenFromVerify(ret);

        this.authCallbackMessage = 'Success! Forwarding to user Account';
        this.authCallbackIsError = false;
        this.authCallbackMessageVisible = true;

        timer(3000).subscribe(() => {
          this.authCallbackMessageVisible = false;
          this.router.navigate(['account']);
        });
      } else if (url.includes('message')) {
        this.authCallbackMessage = decodeURIComponent(this.findParam(params, 'message').replace(/\+/g, '%20'));
        this.authCallbackIsError = false;
        this.authCallbackMessageVisible = true;
      } else if (url.includes('error')) {
        this.authCallbackMessage = decodeURIComponent(this.findParam(params, 'error_description').replace(/\+/g, '%20'));
        this.authCallbackIsError = true;
        this.authCallbackMessageVisible = true;
      }
    } 
  }
  private findParam(list: string[], param: string) {
    return list.find(x => x.startsWith(param))?.split('=')[1] ?? '';
  }

  isHome() {
    return false;//this.router.url == '/';
  }

  eventDetail(){
    return this.router.url.includes('/events/detail/');
  }

  searchPage(){
    return false;//this.router.url.includes('/search') || this.router.url == '/events';
  }

}
