<ng-container *ngIf="event">
  <ng-container *ngrxLet="sponsors$ as sponsors">
    <ng-container *ngIf="sponsors">
      <h2 class="mb-4">Sponsors</h2>
      <ng-container *ngIf="sponsors && sponsors.length > 0; else noSponsors;">
        <div class="-mx-6 grid grid-cols-2 gap-0.5 overflow-hidden sm:mx-0 sm:rounded-2xl sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
          <ng-container *ngFor="let sponsor of sponsors">
            <div class="bg-gray-400/5 p-6 sm:p-10">
              <!--
              <img class="max-h-12 w-full object-contain" src="{{sponsor.logo_url}}" alt="{{sponsor.sponsor_name}}" width="158" height="48">
              -->
              <p class="max-h-12 w-full text-center text-sm text-gray-500">{{sponsor.sponsor_name}}<br /><small class="text-gray-400">{{sponsor.package_name}}</small></p>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #noSponsors>
        <ui-no-data-placeholder icon="fa-hand-holding-usd" heading="No sponsors yet... You could be the first!"></ui-no-data-placeholder>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>