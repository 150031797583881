<ng-container *ngIf="this.link != ''">
  <div class="bg-white">
    <div class="mx-auto max-w-7xl py-24 sm:py-16">
      <div class="relative isolate overflow-hidden bg-green-900 px-6 py-12 text-center shadow-2xl rounded-3xl sm:px-12">
        <h2 class="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">{{this.header}}</h2>
        <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">{{this.content}}</p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <a routerLink="{{this.link}}" class="call-to-action-secondary">Get started</a>
        </div>
        <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]" aria-hidden="true">
          <circle cx="512" cy="512" r="512" fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fill-opacity="0.7" />
          <defs>
            <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
              <stop stop-color="#388e3c" />
              <stop offset="1" stop-color="#388e3c" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</ng-container>