import { Component, Input } from "@angular/core";
import { EventPackage } from '@app/models/event-package.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'ui-package-purchase',
  templateUrl: './package-purchase.component.html',
})
export class UiPackagePurchaseComponent {
  @Input() eventPackage:EventPackage = {} as EventPackage;
  @Input() actionButtonText:string = 'Select';
  @Input() preview:boolean = false;
  @Input() allowPurchase:boolean | undefined = true;
  @Input() type:string = 'golfer';

  constructor(
    private router: Router,
  ) { }
  
  startPackagePurchase(eventId:string, packageId:string) {
    if(!this.preview && this.allowPurchase){
      this.router.navigate(['/orders/new/'+eventId+'/'+packageId]);
    }
  }

}

