import { Component, Input, Output, EventEmitter } from "@angular/core";
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'ui-modal',
  templateUrl: './modal.component.html',
  animations: [ 
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('200ms ease-out', style({  opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class UiModalComponent {
  @Input('title') title: string = 'Create';
  @Input() showCloseButton: boolean = true;
  
  _isVisible: boolean = false;
  get isVisible(): boolean {
    return this._isVisible;
  }
  @Input('isVisible') set isVisible(isVisible: boolean) {
    this._isVisible = isVisible;
    this.isVisibleChange.emit(isVisible);
  }
  @Output() isVisibleChange = new EventEmitter<boolean>();
  
  constructor() { }

  toggleModal(){
    this.isVisible = !this.isVisible;
    this.isVisibleChange.emit(this.isVisible);
  }
  
}

