import { Injectable } from '@angular/core';
import { RpcUserContactSearchService, UserContactInformationService } from '@lib/core';
import { AuthService } from './auth.service';
import { UserContactInformation } from '@app/models/user-contact-information.interface';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserContactHelper {

  constructor(
    private service: UserContactInformationService,
    private authService: AuthService,
    private searchFunction: RpcUserContactSearchService,
  ) { }

  getById(id:string):Observable<UserContactInformation> {
    return this.service.userContactInformationGet({id: 'eq.' + id})
      .pipe(map(array => array[0]));
  }

  getAll(): Observable<UserContactInformation[]> {
    return this.service.userContactInformationGet({
      order: 'display_name'
    });
  }

  getByIds(ids:string[]):Observable<UserContactInformation[]> {
    return this.service.userContactInformationGet({id: 'in.(' + ids.join(',') + ')'});
  }

  searchByText(search_text: string): Observable<UserContactInformation[]> {
    return this.searchFunction.rpcUserContactSearchPost({
      args: {
        search_text: search_text
      }
    });
  }

}
