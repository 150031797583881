import { GolfEventGetRequestParams } from "@lib/core";

export interface GolfEventDefaultParams extends GolfEventGetRequestParams { }
export class GolfEventDefaultParams {
  constructor() {
    this.select = "*,\
      organizer(*,stripe_account(*)),\
      golf_course(*),\
      event_package(id,golf_event_id,category,display_name,description,order,price,\
        event_package_contents(id,qty,order,\
          event_package_reward_type(display_name))),\
      event_fee(processing_fee),\
      profile_photo:s3_file!profile_photo_id_fk(*),\
      header_photo:s3_file!header_photo_id_fk(*)";
    this.order = "event_datetime.asc";
  }
}
