/**
 * Utility
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PackageOrder } from './packageOrder';
import { EventContact } from './eventContact';
import { EventPackage } from './eventPackage';


export interface PrepaidPackage { 
    id?: string;
    eventPackageId?: string;
    invitedEmail?: string | null;
    createdAt?: string;
    createdById?: string;
    lastViewedAt?: string | null;
    packageOrderId?: string | null;
    prepaidAmount?: number;
    prepaidMethod?: string | null;
    eventContactId?: string | null;
    eventContact?: EventContact;
    eventPackage?: EventPackage;
    packageOrder?: PackageOrder;
}

