import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadModule } from '@app/components/head/head.module';
import { UiModule } from '@app/components/ui/ui.module';
import { BlogUiModule } from '@app/components/blog-ui/blog-ui.module';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { UpcomingEventComponent } from './home/upcoming-event/upcoming-event.component';

@NgModule({
  declarations: [
    HomeComponent,
  ],
  imports: [
    CommonModule,
    HeadModule,
    BlogUiModule,
    UiModule,
    RouterModule,
    LetDirective,
    UpcomingEventComponent,
  ]
})
export class LandingModule { 
  constructor() {
    
  }
}
