/**
 * Utility
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventPackageRewardType } from './eventPackageRewardType';
import { PrintTemplate } from './printTemplate';
import { EventPackage } from './eventPackage';


export interface EventPackageContent { 
    packageId?: string;
    rewardTypeId?: string;
    qty?: number | null;
    order?: number;
    id?: string;
    _package?: EventPackage;
    printTemplate?: PrintTemplate;
    rewardType?: EventPackageRewardType;
}

