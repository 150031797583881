/**
 * Utility
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type S3FileType = 'ImageComponent' | 'ImageRender' | 'ImageProfilePhoto' | 'ImageSponsorLogo' | 'ImageEventProfile' | 'ImageEventHeader' | 'ImageOrganizerProfile' | 'ImageOrganizerHeader' | 'ImageCourseProfile' | 'ImageCourseHeader';

export const S3FileType = {
    ImageComponent: 'ImageComponent' as S3FileType,
    ImageRender: 'ImageRender' as S3FileType,
    ImageProfilePhoto: 'ImageProfilePhoto' as S3FileType,
    ImageSponsorLogo: 'ImageSponsorLogo' as S3FileType,
    ImageEventProfile: 'ImageEventProfile' as S3FileType,
    ImageEventHeader: 'ImageEventHeader' as S3FileType,
    ImageOrganizerProfile: 'ImageOrganizerProfile' as S3FileType,
    ImageOrganizerHeader: 'ImageOrganizerHeader' as S3FileType,
    ImageCourseProfile: 'ImageCourseProfile' as S3FileType,
    ImageCourseHeader: 'ImageCourseHeader' as S3FileType
};

