/**
 * Financial
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PackageOrder } from './packageOrder';
import { EventFee } from './eventFee';
import { GolfCourse } from './golfCourse';
import { EventContact } from './eventContact';
import { Organizer } from './organizer';
import { S3File } from './s3File';
import { EventPackage } from './eventPackage';


export interface GolfEvent { 
    id?: string;
    ownerId?: string;
    createdDate?: string;
    displayName?: string | null;
    eventDatetime?: string;
    description?: string | null;
    golfCourseId?: string;
    isPrivate?: boolean;
    profilePhotoId?: string | null;
    headerPhotoId?: string | null;
    active?: boolean | null;
    setupStep?: number;
    eventContacts?: Array<EventContact> | null;
    eventFee?: EventFee;
    eventPackages?: Array<EventPackage> | null;
    golfCourse?: GolfCourse;
    headerPhoto?: S3File;
    owner?: Organizer;
    packageOrders?: Array<PackageOrder> | null;
    profilePhoto?: S3File;
}

