/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 11.2.0 (c820efb)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PrepaidPackage { 
    /**
     * Note: This is a Primary Key.<pk/>
     */
    id: string;
    /**
     * Note: This is a Foreign Key to `event_package.id`.<fk table=\'event_package\' column=\'id\'/>
     */
    event_package_id: string;
    invited_email: string;
    created_at: string;
    created_by_id: string;
    last_viewed_at?: string;
    /**
     * Note: This is a Foreign Key to `package_order.id`.<fk table=\'package_order\' column=\'id\'/>
     */
    package_order_id?: string;
    prepaid_amount: string;
    prepaid_method?: string;
    /**
     * Note: This is a Foreign Key to `event_contact.id`.<fk table=\'event_contact\' column=\'id\'/>
     */
    event_contact_id?: string;
}

