<head-meta 
title="Better Golf Events | My Golf Events"
description="We specialize in organizing and executing exceptional golf events for golfers, sponsors, and organizers."
url="/"
/>

<div class="relative isolate overflow-hidden bg-white  mb-32">
  <svg class="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
    <defs>
      <pattern id="0787a7c5-978c-4f66-83c7-11c213f99cb7" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
        <path d="M.5 200V.5H200" fill="none" />
      </pattern>
    </defs>
    <rect width="100%" height="100%" stroke-width="0" fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)" />
  </svg>
  <div class="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:pt-40">
    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
      
      <h1 class="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Better golf events, for everyone.</h1>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        Welcome to MyGolfEvents, the premier platform that unites golfers, organizers, and sponsors in a shared pursuit of extraordinary golfing experiences. 
        Our goal is to foster a community of like-minded individuals who share a love for golf and a desire to make the world a better place. 
        From golfers seeking camaraderie to organizers striving for flawless events and sponsors looking to make a meaningful impact, our platform brings all stakeholders together under one vision: achieve greatness on and off the fairways.
        <a routerLink="/about" class="text-green-500 hover:text-green-700">Learn more <i class="fa-light fa-arrow-right"></i></a>
      </p>
    </div>
    <div class="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
      <div class="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
        <div class="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
          <img src="/assets/img/hero/screenshots/edit.png" alt="" class="w-[76rem] rounded-md shadow-2xl ring-1 ring-gray-900/10">
        </div>
      </div>
    </div>
  </div>
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mb-6">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">All-in-one platform</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        Together, we're shaping the future of golfing events: uniting golfers, organizers, and sponsors as we create extraordinary moments, forge lifelong connections, and celebrate the joy of golf. 
      </p>
    </div>
    <dl class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 md:mx-0 md:max-w-none md:grid-cols-3">
      <div>
        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
          <i class="fa-light fa-golf-club text-green-500"></i>
          For Golfers
        </dt>
        <dd class="mt-1 text-gray-600">
          At MyGolfEvents, golfers of all skill levels are at the heart of what we do. 
          Whether you're a seasoned pro seeking fierce competition or a beginner looking to learn the game, our events cater to every golfer's desires. 
          Join us to forge new friendships, celebrate the spirit of the game, and embark on unforgettable golfing adventures.
          <a routerLink="/solutions/golfers" class="text-green-500 hover:text-green-700">Learn more <i class="fa-light fa-arrow-right"></i></a>
        </dd>
      </div>
      <div>
        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
          <i class="fa-light fa-golf-flag-hole text-green-500"></i>
          For Organizers
        </dt>
        <dd class="mt-1 text-gray-600">
          Organizing a golf event should be an exciting journey, and that's where we come in. 
          MyGolfEvents empowers organizers with the tools and support needed to craft seamless and remarkable events.
          Whether it's a corporate outing, a charity tournament, or a community gathering, our sets up every event for success.
          <a routerLink="/solutions/organizers" class="text-green-500 hover:text-green-700">Learn more <i class="fa-light fa-arrow-right"></i></a>
        </dd>
      </div>
      <div>
        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
          <i class="fa-light fa-hand-holding-usd text-green-500"></i>
          For Sponsors
        </dt>
        <dd class="mt-1 text-gray-600">
          Sponsoring a golf event is more than just a marketing opportunity; it's a chance to make a lasting impact. 
          At MyGolfEvents, we connect sponsors with engaged audiences who share a passion for the game.
          Join us in supporting the golfing community while showcasing your brand's commitment to excellence and meaningful engagements.
          <a routerLink="/solutions/sponsors" class="text-green-500 hover:text-green-700">Learn more <i class="fa-light fa-arrow-right"></i></a>
        </dd>
      </div>
    </dl>
  </div>
</div>

<!--
<div class="relative isolate">
  <div class="overflow-hidden">
      <div class="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
          <div class="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
          <div class="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
              <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Better golf events, for everyone.</h1>
              <p class="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">Introducing the ultimate tool to revolutionize your golf events. Whether you're organizing a corporate tournament, charity fundraiser, or a friendly outing with friends, our innovative platform takes the hassle out of event management, leaving you with more time to focus on what matters most.</p>
              <div class="mt-10 flex items-center gap-x-6">
                  <button routerLink="/events/create" class="call-to-action-primary">Start Event</button>
              </div>
          </div>
          <div class="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
              <div class="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
              <div class="relative">
                  <img src="/assets/img/home/1.jpg" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg">
                  <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"></div>
              </div>
              </div>
              <div class="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
              <div class="relative">
                  <img src="/assets/img/home/2.jpg" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg">
                  <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"></div>
              </div>
              <div class="relative">
                  <img src="/assets/img/home/4.jpg" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg">
                  <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"></div>
              </div>
              </div>
              <div class="w-44 flex-none space-y-8 pt-32 sm:pt-0">
              <div class="relative">
                  <img src="/assets/img/home/5.jpg" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg">
                  <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"></div>
              </div>
              <div class="relative">
                  <img src="/assets/img/home/13.jpg" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg">
                  <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"></div>
              </div>
              </div>
          </div>
          </div>
      </div>
  </div>
</div>
-->

<!--
<div>
    <div class="pb-4 sm:pb-8">
        <div class="mx-auto w-full px-6 lg:px-8">
          <div class="mx-auto mt-16 grid w-full auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <article class="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-white px-8 pb-8 pt-80 sm:pt-48 lg:pt-80 cursor-pointer" routerLink="/search">
                <p class="text-3xl font-bold tracking-tight text-gray-50 sm:text-4xl">Golfers</p>
                <img src="/assets/img/hero/home/golfer.jpg" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover">
                <div class="absolute inset-0 -z-10 bg-gradient-to-t from-green-900 via-green-900/40"></div>
                <div class="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-green-50/10"></div>
                <h3 class="mt-3 text-lg font-semibold leading-6 text-green-200">
                    <span class="absolute inset-0"></span>
                    Find &amp; play in golf events nearby
                </h3>
            </article>
            <article class="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-white px-8 pb-8 pt-80 sm:pt-48 lg:pt-80 cursor-pointer" routerLink="/events/create">
                <p class="text-3xl font-bold tracking-tight text-gray-50 sm:text-4xl">Organizers</p>
                <img src="/assets/img/hero/home/organizer.jpg" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover">
                <div class="absolute inset-0 -z-10 bg-gradient-to-t from-green-900 via-green-900/40"></div>
                <div class="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-green-50/10"></div>
                <h3 class="mt-3 text-lg font-semibold leading-6 text-green-200">
                    <span class="absolute inset-0"></span>
                    Track &amp; organize all aspects of a golf event
                </h3>
            </article>
            <article class="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-white px-8 pb-8 pt-80 sm:pt-48 lg:pt-80 cursor-pointer" routerLink="/search">
                <p class="text-3xl font-bold tracking-tight text-gray-50 sm:text-4xl">Sponsors</p>
                <img src="/assets/img/hero/home/sponsor.jpg" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover">
                <div class="absolute inset-0 -z-10 bg-gradient-to-t from-green-900 via-green-900/40"></div>
                <div class="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-green-50/10"></div>
                <h3 class="mt-3 text-lg font-semibold leading-6 text-green-200">
                    <span class="absolute inset-0"></span>
                    Get brand exposure in your community
                </h3>
            </article>
          </div>
          <div class="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-white p-8 w-full text-center my-8 cursor-pointer" routerLink="/contact">
            <img src="/assets/img/hero/bg3.jpg" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover">
            <div class="absolute inset-0 -z-10 bg-gradient-to-t from-green-900 via-green-900/40"></div>
            <div class="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-green-50/10"></div>
            <p class="text-3xl font-bold tracking-tight text-white sm:text-4xl drop-shadow-md">Register Your Golf Course</p>
          </div>

        </div>
    </div>
</div>
-->

<div class="bg-white my-10">
    <div class="overflow-hidden pt-32 sm:pt-14">
      <div class="from-green-900 to-green-800 bg-gradient-to-br">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="relative pb-16 pt-48 sm:pb-24">
            <div>
              <h2 class="text-2xl font-bold tracking-tight text-white md:text-4xl">
                <span class="text-xl text-green-200">Hey Golfers! Don't Miss These</span>
                <br />
                Upcoming Events
              </h2>
              <div class="mt-6 text-base">
                <a routerLink="/search" class="call-to-action-secondary">
                  Search Nearby Events
                </a>
              </div>
            </div>
            <ng-container *ngrxLet="events$ as events">
              <div class="absolute -top-32 left-1/2 -translate-x-1/2 transform sm:top-6 sm:translate-x-0">
                <div class="ml-24 flex min-w-max space-x-6 sm:ml-3 lg:space-x-8">
                  <div class="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                    <div class="flex-shrink-0">
                      <app-upcoming-event [event]="events[0]" />
                    </div>
                    <div class="mt-6 flex-shrink-0 sm:mt-0">
                      <app-upcoming-event *ngIf="events[3] && events[4] && events[5]" [event]="events[3]" />
                    </div>
                  </div>
                  <div class="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                    <div class="flex-shrink-0">
                      <app-upcoming-event [event]="events[1]" />
                    </div>
                    <div class="mt-6 flex-shrink-0 sm:mt-0">
                      <app-upcoming-event *ngIf="events[3] && events[4] && events[5]" [event]="events[4]" />
                    </div>
                  </div>
                  <div class="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                    <div class="flex-shrink-0">
                      <app-upcoming-event [event]="events[2]" />
                    </div>
                    <div class="mt-6 flex-shrink-0 sm:mt-0">
                      <app-upcoming-event *ngIf="events[3] && events[4] && events[5]" [event]="events[5]" />
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
</div>


<div class="overflow-hidden bg-white py-32">
  <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
    <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
      <div class="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Organize your next event with us</h2>
        <p class="mt-6 text-xl leading-8 text-gray-600">Looking to host an exceptional golf event that stands out from the rest? Look no further! MyGolfEvents is your trusted partner in planning and executing unforgettable golfing experiences.</p>
        <p class="mt-6 text-base leading-7 text-gray-600">Join a growing list of satisfied clients who have entrusted their golf events to us. Let MyGolfEvents be your partner in creating an extraordinary golfing experience that exceeds expectations.</p>
        <div class="mt-10 flex">
          <a routerLink="/events/create" class="call-to-action-secondary">
            Get Started For Free
          </a>
          <span class="text-sm font-base leading-6 text-gray-400 ml-3 mt-1">No Credit Card Required</span>
        </div>
      </div>
      <div class="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
        <div class="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end"> <!-- top large -->
          <img src="/assets/img/hero/screenshots/order.png" alt="" class="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover object-left-top drop-shadow-lg">
        </div>
        <div class="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
          <div class="order-first flex w-64 flex-none justify-end self-end lg:w-auto"> <!-- left small -->
            <img src="/assets/img/home/3.jpg" alt="" class="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover drop-shadow-lg">
          </div>
          <div class="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">  <!-- bottom large -->
            <img src="/assets/img/hero/screenshots/edit.png" alt="" class="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover object-left-top drop-shadow-lg">
          </div>
          <div class="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none"> <!--right small-->
            <img src="/assets/img/home/2.jpg" alt="" class="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover  drop-shadow-lg">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="bg-gradient-to-b from-green-50 via-white to-white border-0 border-t border-green-100  py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl text-center">
      <h2 class="text-3xl font-bold tracking-tight text-green-900 sm:text-4xl">Latest from the blog</h2>
      <p class="mt-2 text-lg leading-8 text-green-600">Get tips, expert advice, and ideas how to make your next golf event better.</p>
    </div>
    <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
      <ng-container *ngrxLet="posts$ as posts">
        <ng-container *ngFor="let post of posts">
            <blog-wp-summary [post]="post" [template]="2"></blog-wp-summary>
        </ng-container>
      </ng-container>
    </div>
    <div class="mt-16 text-center">
      <a routerLink="/blog" class="call-to-action-primary">
        View all posts
      </a>
    </div>
  </div>
</div>