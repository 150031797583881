
<ui-modal title="Reset Password" [(isVisible)]="this.isVisible">
    <div class="mt-2">
        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form class="space-y-6" [formGroup]="resetForm">
                <div>
                    <div class="flex items-center justify-between">
                        <label for="email" class="label">Email</label>
                        <div class="text-sm"></div>
                    </div>
                    <div class="mt-2">
                        <input formControlName="email" id="email" name="email" type="email"
                            autocomplete="email" required class="input">
                        <ng-container *ngIf="resetForm.controls['email'] as ctl">
                            <div *ngIf="ctl.invalid && (ctl.dirty || ctl.touched)"
                                class="alert">
                                <div *ngIf="ctl.errors?.['required']">
                                    Email is required.
                                </div>
                                <div *ngIf="ctl.errors?.['email']">
                                    Email must be a valid address.
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <ng-container *ngIf="submit$">
                    <ng-container *ngrxLet="submit$ as submit">
                        <div *ngIf="submit" class="success-box">
                            <p>Password reset email sent, check {{ emailToReset ?? 'your email' }} for instructions to reset your password.</p>
                        </div>
                        <div *ngIf="!submit" class="error-box">
                            <p>Could not reset your password. Please try again.</p>
                        </div>
                    </ng-container>
                </ng-container>
                <div>
                    <button type="submit" class="submit" (click)="reset()"
                        [disabled]="!resetForm.valid">Send Password Reset Email</button>
                </div>
            </form>
        </div>
    </div>
</ui-modal>