import { Injectable } from '@angular/core';
import { OrganizerService, RpcOrganizerSearchService } from '@lib/core';
import { Observable, first, map } from 'rxjs';
import { Organizer } from '@app/models/organizer.interface';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizerHelper {

  constructor(
    private service: OrganizerService,
    private authService: AuthService,
    private searchFunction: RpcOrganizerSearchService,
  ) { }

  getOrganizerById(id:string):Observable<Organizer> {
    return this.service.organizerGet({id: 'eq.' + id})
      .pipe(map(array => array[0])).pipe(first());
  }

  getAllOrganizers(): Observable<Organizer[]> {
    return this.service.organizerGet({
      order: 'display_name'
    });
  }

  getMyProfile():Observable<Organizer> {
    return this.service.organizerGet({id: 'eq.' + this.authService.getUserId()})
      .pipe(map(array => array[0])).pipe(first());
  }

  createProfile(input: Organizer): Observable<boolean> {
    return this.service.organizerPost({
      organizer: input
    }, 'response')
    .pipe(map(r => r.ok));
  }

  searchByText(search_text: string): Observable<Organizer[]> {
    return this.searchFunction.rpcOrganizerSearchPost({
      args: {
        search_text: search_text
      }
    });
  }

}
