<ng-container *ngIf="this.type == 'golfer'; else notGolfer">
  <div class="mb-1 sm:flex sm:items-center sm:justify-between" (click)="startPackagePurchase(eventPackage.golf_event_id, eventPackage.id)">
    <h3 class="text-base font-semibold leading-6 text-gray-900">{{eventPackage.display_name}}</h3>
    <div class="mt-3 sm:ml-4 sm:mt-0">
      <button type="button" (click)="startPackagePurchase(eventPackage.golf_event_id, eventPackage.id)" class="button call-to-action-primary">{{eventPackage.price | slice:0:-3 }}</button>
    </div>
  </div>
</ng-container>
<ng-template #notGolfer>
  <div>
    <div class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
      <h3 class="text-base font-semibold leading-6 text-gray-900">{{eventPackage.display_name}}</h3>
      <!--<p class="ml-2 mt-1 truncate text-sm text-gray-500">{{eventPackage.price}}</p>-->
      <div class="mt-3 sm:ml-4 sm:mt-0">
        <button type="button" (click)="startPackagePurchase(eventPackage.golf_event_id, eventPackage.id)" class="button call-to-action-primary">
          <!--{{this.actionButtonText}}-->
          {{eventPackage.price | slice:0:-3 }}
        </button>
      </div>
    </div>
    <div>
      <p class="mt-1 text-base leading-6 text-gray-900 sm:col-span-2 sm:mt-0" *ngIf="eventPackage.description != '' && eventPackage.description != null">{{eventPackage.description}}</p>
      <ul class="mt-1">
        <ng-container *ngIf="eventPackage.event_package_contents !== undefined && eventPackage.event_package_contents.length > 0">
          <ng-container *ngFor="let event_package_content of eventPackage.event_package_contents">
            <ng-container *ngIf="event_package_content.event_package_reward_type !== undefined && event_package_content.qty !== undefined">
              <li class="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0" *ngIf="event_package_content.qty > 0"><i class="fa-light fa-arrow-right mr-2"></i> <span class="bg-gray-100 rounded-full text-gray-500 mr-1 px-1.5 text-center">{{event_package_content.qty}}</span> {{event_package_content.event_package_reward_type.display_name}}{{event_package_content.qty > 1 ? 's' : ''}}</li>
            </ng-container>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
</ng-template>
  