import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { AngularPinturaModule } from '@pqina/angular-pintura';
import { HeadMetaComponent } from './meta/meta.component';
import { Meta } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    HeadMetaComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LetDirective,
    AngularPinturaModule,
    ReactiveFormsModule,
  ],
  exports: [
    HeadMetaComponent,
  ],
  providers: [
    Meta
  ]
})
export class HeadModule { }
