import { Component, Input } from "@angular/core";
import { PackageOrder } from '@app/models/package-order.interface';
import { GolfEventPackageHelper } from "@app/services/golf-event-package-helper.service";
import { GolferHelper } from "@app/services/golfer-helper.service";
import { SponsorHelper } from "@app/services/sponsor-helper.service";
import { Observable, map } from "rxjs";

@Component({
  selector: 'ui-event-orders-table',
  templateUrl: './event-orders-table.component.html'
})
export class UiEventOrdersTableComponent {
  @Input() orders: PackageOrder[] | null = null;
  sponsorNames: Map<string,string> = new Map<string, string>;
  golferNames: Map<string,string> = new Map<string, string>;

  constructor(
    private sponsorHelper: SponsorHelper,
    private golferHelper: GolferHelper,
  ) {
  }

  ngOnInit() {
    if(this.orders) {
      this.sponsorHelper.getSponsorsByIds(this.orders.map(po => po.purchaser_user_id))
        .subscribe(sponsors => {
          sponsors.forEach(s => this.sponsorNames.set(s.id, s.display_name ?? ""))
        });
      this.golferHelper.getGolfersByIds(this.orders.map(po => po.purchaser_user_id))
        .subscribe(golfers => {
          golfers.forEach(s => this.golferNames.set(s.id, s.display_name ?? ""))
        });
    }
  }

  getPackageSubTotal(orders: PackageOrder[]): string {
    let total = 0;
    total = orders.reduce((acc, order) => {
      if( order.total ) {
        let price = GolfEventPackageHelper.formattedDollarToNumber(order.total);
        return acc + price;
      } else {
        return acc;
      }
    }, 0);
    if(total != 0 && !isNaN(total)) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total);
    }
    return '';
  }

  getPackageTotalDonations(orders: PackageOrder[]): string {
    let total = 0;
    total = orders.reduce((acc, order) => {
      if( order.additional_donation ) {
        let price = GolfEventPackageHelper.formattedDollarToNumber(order.additional_donation);
        return acc + price;
      } else {
        return acc;
      }
    }, 0);
    if(total != 0 && !isNaN(total)) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total);
    }
    return '$0.00';
  }

  getPackageTotals(orders: PackageOrder[]): string {
    let total = 0;
    total = orders.reduce((acc, order) => {
      if( order.total ) {
        let price =GolfEventPackageHelper.formattedDollarToNumber(order.total);
        return acc + price;
      } else {
        return acc;
      }
    }, 0);
    if(total != 0 && !isNaN(total)) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total);
    }
    return '';
  }

}
