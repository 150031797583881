import { Component, Input } from "@angular/core";
import { GolfEvent } from '@app/models/golf-event.interface';
import { EventPackage } from '@app/models/event-package.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'ui-new-order-listing',
  templateUrl: './new-order-listing.component.html',
})
export class UiNewOrderListingComponent {
  @Input() event$: Observable<GolfEvent> = new Observable<GolfEvent>();
  @Input() package$: Observable<EventPackage> = new Observable<EventPackage>();
  @Input() showPrice: boolean = true;

  constructor(

  ) { }
  
}

