<div>
  <div class="sm:hidden">
    <label for="tabs" class="sr-only">Select a tab</label>
    <select (change)="this.onMenuChange($event)" id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm">
      <option value="/account" [selected]="this.isActive('/account')">My Account</option>
      <option value="/account/orders" [selected]="this.isActive('/account/orders')">Orders</option>
      <option value="/account/organizer" [selected]="this.isActive('/account/organizer')">Organizer</option>
      <option value="/account/sponsor" [selected]="this.isActive('/account/sponsor')">Sponsor</option>
      <option value="/account/golfer" [selected]="this.isActive('/account/golfer')">Golfer</option>
    </select>
  </div>
  <div class="hidden sm:block">
    <div class="flex h-14 justify-between">
      <nav class="hidden sm:flex sm:items-center sm:space-x-4">
        <a routerLink="/account" routerLinkActive="bg-green-500 text-white" [routerLinkActiveOptions]="{exact: true}" class="rounded-md px-3 py-2 text-sm font-medium hover:bg-green-50">My Account</a>
        <a routerLink="/account/orders" routerLinkActive="bg-green-500 text-white" class="rounded-md px-3 py-2 text-sm font-medium hover:bg-green-50">Orders</a>
        <a routerLink="/account/organizer" routerLinkActive="bg-green-500 text-white" class="rounded-md px-3 py-2 text-sm font-medium hover:bg-green-50">Organizer</a>
        <a routerLink="/account/sponsor" routerLinkActive="bg-green-500 text-white" class="rounded-md px-3 py-2 text-sm font-medium hover:bg-green-50">Sponsor</a>
        <a routerLink="/account/golfer" routerLinkActive="bg-green-500 text-white" class="rounded-md px-3 py-2 text-sm font-medium hover:bg-green-50">Golfer</a>
      </nav>
    </div>
  </div>
</div>
  